import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Row, Col, Container, Card, CardBody, CardHeader, Collapse, Label, Input, Button, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import { Btn, H5, H6, Spinner } from '../../../AbstractElements';
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import TreeNode from './TreeNode';
import ReactPaginate from 'react-paginate';
import ReactApexChart from 'react-apexcharts';
import configDB from '../../../Config/ThemeConfig';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { X } from 'react-feather';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AnalyticsView = () => {

    if (localStorage.getItem("accessAnalytics") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { t } = useTranslation();
    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [isOpen, setIsOpen] = useState({ 1: false, 2: false, 3: false, 4: false, 5: false });
    const [subAccordionOpen, setSubAccordionOpen] = useState({ 6: false, 7: false });


    const toggle = (index) => {
        setIsOpen((prevState) => ({
            ...Object.keys(prevState).reduce((acc, key) => ({
                ...acc,
                [key]: key === String(index) ? !prevState[key] : false,
            }), {}),
        }));
    };

    const toggleSubAccordion = (index) => {
        setSubAccordionOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const getYesterdayDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    const [locations, setLocations] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredLocations, setFilteredLocations] = useState(locations);
    const [checkedItems, setCheckedItems] = useState({});
    const [dateType, setDateType] = useState("custom");
    const [visualizationType, setVisualizationType] = useState("Table");
    const [selectedMetrics, setSelectedMetrics] = useState(["Sales", "Transaction Count", "ATV"]);
    const [selectedRevenue, setSelectedRevenue] = useState(["Revenue Share Amount"]);
    const [selectedGroupBy, setSelectedGroupBy] = useState("Location");
    const [groupBy, setGroupBy] = useState("location");
    const [startDate, setStartDate] = useState(getYesterdayDate());
    const [endDate, setEndDate] = useState(getYesterdayDate());
    const [startDate2, setStartDate2] = useState('');
    const [endDate2, setEndDate2] = useState('');
    const [selectedTime, setSelectedTime] = useState('Store Hours');
    const [showAdditionalDateRange, setShowAdditionalDateRange] = useState(false);
    const [level, setLevel] = useState(0);
    const [selectedTypeIdArray, setSelectedTypeIdArray] = useState([]);
    const [metricSales, setMetricSales] = useState(1);
    const [metricTransactionCount, setMetricTransactionCount] = useState(1);
    const [metricATV, setMetricATV] = useState(1);
    const [metricFBSales, setMetricFBSales] = useState(0);
    const [metricRevenueShare, setMetricRevenueShare] = useState(1);
    const [metricRevenueSharePerc, setMetricRevenueSharePerc] = useState(0);
    const [metricBaseRent, setMetricBaseRent] = useState(0);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [fbAnalyticsData, setFbAnalyticsData] = useState([]);
    const [analyticsData2, setAnalyticsData2] = useState([]);
    const [fbAnalyticsData2, setFbAnalyticsData2] = useState([]);
    const [interleavedMetrics, setInterleavedMetrics] = useState([]);
    const [interleavedFBMetrics, setInterleavedFBMetrics] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [itemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        axios.post(`${config.hostname}/analytics/location`, { concessionaire_id }, configheader)
            .then(response => {
                const transformedData = transformData(response.data);
                setLocations(transformedData);
                setFilteredLocations(transformedData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);


    const transformData = (data) => {
        return data.map(item => ({
            id: item.concessionaire.id,
            text: item.concessionaire.name,
            children: item.concessionaire.countries.map(country => ({
                id: country.id,
                text: country.name,
                children: country.properties.map(property => ({
                    id: property.id,
                    text: property.name,
                    children: property.tenants.map(tenant => ({
                        id: tenant.id,
                        text: tenant.name
                    })).sort((a, b) => a.text.localeCompare(b.text))
                })).sort((a, b) => a.text.localeCompare(b.text))
            })).sort((a, b) => a.text.localeCompare(b.text))
        })).sort((a, b) => a.text.localeCompare(b.text));
    };



    const handleToggle = (nodeId, children) => {
        const newCheckedItems = { ...checkedItems, [nodeId]: !checkedItems[nodeId] };

        if (children) {
            const checkChildren = (nodes, isChecked) => {
                nodes.forEach(node => {
                    newCheckedItems[node.id] = isChecked;
                    if (node.children) {
                        checkChildren(node.children, isChecked);
                    }
                });
            };
            checkChildren(children, newCheckedItems[nodeId]);
        }

        setCheckedItems(newCheckedItems);
    };

    const handleMetricToggle = (metric) => {
        setSelectedMetrics(prevMetrics =>
            prevMetrics.includes(metric)
                ? prevMetrics.filter(item => item !== metric)
                : [...prevMetrics, metric]
        );
        if (metric === "Sales") {
            setMetricSales(prev => prev === 1 ? 0 : 1);
        }
        if (metric === "Transaction Count") {
            setMetricTransactionCount(prev => prev === 1 ? 0 : 1);
        }

        if (metric === "ATV") {
            setMetricATV(prev => prev === 1 ? 0 : 1);
        }

        if (metric === "F&B Sales") {
            setMetricFBSales(prev => prev === 1 ? 0 : 1);
        }
    };

    const handleRevenueToggle = (revenue) => {
        setSelectedRevenue(prevRevenue =>
            prevRevenue.includes(revenue)
                ? prevRevenue.filter(item => item !== revenue)
                : [...prevRevenue, revenue]
        );
        if (revenue === "Revenue Share Amount") {
            setMetricRevenueShare(prev => prev === 1 ? 0 : 1);
        }
        if (revenue === "Revenue Share %") {
            setMetricRevenueSharePerc(prev => prev === 1 ? 0 : 1);
        }

        if (revenue === "Base Rent") {
            setMetricBaseRent(prev => prev === 1 ? 0 : 1);
        }
    };



    const toggleAdditionalDateRange = () => {
        setShowAdditionalDateRange(!showAdditionalDateRange);
    };

    const handleDateSelectionchange = (e) => {
        const currentDate = new Date();
        let startDate, endDate;
        const selectedDate = e.target.value;

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        if (selectedDate === 'today') {
            startDate = formatDate(currentDate);
            endDate = formatDate(currentDate);
        } else if (selectedDate === 'yesterday') {
            const yesterday = new Date(currentDate);
            yesterday.setDate(currentDate.getDate() - 1);
            startDate = formatDate(yesterday);
            endDate = formatDate(yesterday);
        } else if (selectedDate === 'thisWeek') {
            const dayOfWeek = currentDate.getDay(); // 0 (Sun) to 6 (Sat)
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
            startDate = formatDate(startOfWeek);
            endDate = formatDate(currentDate);
        } else if (selectedDate === 'thisMonth') {
            const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            startDate = formatDate(startOfMonth);
            endDate = formatDate(currentDate);
        } else if (selectedDate === 'thisYear') {
            const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
            startDate = formatDate(startOfYear);
            endDate = formatDate(currentDate);
        }

        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleGroupByChange = (e) => {
        const groupBy = e.target.value;
        if (groupBy === 'location') {
            setSelectedGroupBy("Location");
            setGroupBy('location')
        }
        else if (groupBy === 'date') {
            setSelectedGroupBy("Date")
            setGroupBy('date')
        }
        else if (groupBy === 'tenantGroup') {
            setSelectedGroupBy("Tenant Group")
            setGroupBy('tenantGroup')
        }
        else if (groupBy === 'property') {
            setSelectedGroupBy("Property")
            setGroupBy('property')
        }
        else if (groupBy === 'category') {
            setSelectedGroupBy("Category")
            setGroupBy('category')
        }
        else if (groupBy === 'subcategory') {
            setSelectedGroupBy("Subcategory")
            setGroupBy('subcategory')
        }
        else if (groupBy === 'floor') {
            setSelectedGroupBy("Floor")
            setGroupBy('floor')
        }
        else if (groupBy === 'entrance') {
            setSelectedGroupBy("Entrance")
            setGroupBy('entrance')
        }
    }

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterLocations(query);
    };


    const handleRemove = (type) => {
        switch (type) {

            case 'dateRange2':
                setStartDate2(null);
                setEndDate2(null);
                setShowAdditionalDateRange(false);
                break;
        }
    };

    const handleRemoveMetric = (metric) => {
        setSelectedMetrics((prevMetrics) => {
            const newMetrics = prevMetrics.filter((m) => m !== metric);

            if (metric === "Sales") {
                setMetricSales(0);
            }
            if (metric === "Transaction Count") {
                setMetricTransactionCount(0);
            }
            if (metric === "ATV") {
                setMetricATV(0);
            }
            if (metric === "F&B Sales") {
                setMetricFBSales(0);
            }

            return newMetrics;
        });
    };

    const handleRemoveRevenue = (revenue) => {
        setSelectedRevenue((prevRevenue) => {
            const newRevenue = prevRevenue.filter((r) => r !== revenue);

            if (revenue === "Revenue Share Amount") {
                setMetricRevenueShare(0);
            }
            if (revenue === "Revenue Share %") {
                setMetricRevenueSharePerc(0);
            }
            if (revenue === "Base Rent") {
                setMetricBaseRent(0);
            }

            return newRevenue;
        });
    };

    const filterLocations = (query) => {

        if (!query) {
            setFilteredLocations(locations);
            return;
        }

        const filterTree = (nodes, query) => {
            return nodes.map(node => {
                if (node.children) {
                    const filteredChildren = filterTree(node.children, query);
                    if (filteredChildren.length > 0) {
                        return {
                            ...node,
                            children: filteredChildren
                        };
                    } else if (node.text.toLowerCase().includes(query.toLowerCase())) {
                        return { ...node, children: [] };
                    }
                } else if (node.text.toLowerCase().includes(query.toLowerCase())) {
                    return node;
                }
                return null;
            }).filter(node => node !== null);
        };

        const filtered = filterTree(locations, query.toLowerCase());

        setFilteredLocations(filtered);
    };




    const getSelectedLevel = useCallback((currentLevel, CurrentLevelId, isChecked) => {
        if (isChecked) {
            setLevel(currentLevel)
            //setSelectedTypeId(CurrentLevelId)
            setSelectedTypeIdArray((prevArray) => [...prevArray, CurrentLevelId]);
        }
        else {
            setSelectedTypeIdArray((prevArray) => prevArray.filter(id => id !== CurrentLevelId));
        }

    }, [checkedItems, locations]);

    const onLevelChange = useCallback((currentLevel, CurrentLevelId) => {
        if (currentLevel === level) {
            setSelectedTypeIdArray((prevArray) => [...prevArray, CurrentLevelId]);
        }
        else if (currentLevel < level) {
            setLevel(currentLevel);
            setSelectedTypeIdArray([CurrentLevelId]);
        }

    }, [checkedItems, locations, level]);


    const generateReport = useCallback(() => {
        setCurrentPage(0)
        setCurrentPage2(0)

        if (!isAnyLocationSelected()) {
            return;
        }
        setLoading(true)

        let selectedType
        if (level === 0) {
            selectedType = "Concessionaire"
        }
        else if (level === 1) {
            selectedType = "Country"
        }
        else if (level === 2) {
            selectedType = "Property"
        }
        else if (level === 3) {
            selectedType = "Tenant"
        }


        if (showAdditionalDateRange === true && startDate2 !== '' && endDate2 !== '') {
            const data = {
                query: {
                    concessionareId: concessionaire_id,
                    locations: [
                        {
                            type: selectedType,
                            values: selectedTypeIdArray
                        }
                    ],
                    metrics: [
                        {
                            trafficIn: 0,
                            trafficOut: 0,
                            sales: metricSales,
                            transCount: metricTransactionCount,
                            conversionRate: 0,
                            atv: metricATV,
                            fb: metricFBSales,
                            revenueShareAmount: metricRevenueShare,
                            revenueSharePercentage: metricRevenueSharePerc,
                            baseRent: metricBaseRent
                        }
                    ],
                    dateRanges: [
                        {
                            start: startDate,
                            finish: endDate
                        },
                        {
                            start: startDate2,
                            finish: endDate2
                        }

                    ],
                    timeRanges: [
                        {
                            type: "store_hours"
                        }
                    ],
                    groupBy: [
                        groupBy
                    ]
                }

            };
            axios
                .post(`${config.hostname}/analytics/multi`, data, configheader)
                .then((response) => {
                    setAnalyticsData(response.data[0].firstResult.metrics)
                    setAnalyticsData2(response.data[1].secondResult.metrics)
                    setInterleavedMetrics(interleaveData(response.data[0].firstResult.metrics, response.data[1].secondResult.metrics))
                    if (metricFBSales === 1) {
                        setFbAnalyticsData(response.data[0].firstResult.fbMetrics)
                        setFbAnalyticsData2(response.data[1].secondResult.fbMetrics)
                        setInterleavedFBMetrics(interleaveData(response.data[0].firstResult.fbMetrics, response.data[1].secondResult.fbMetrics))
                    }
                    else {
                        setFbAnalyticsData([])
                        setFbAnalyticsData2([])
                    }
                    // metricFBSales === 1 ? setFbAnalyticsData(response.data[0].fbMetrics) : setFbAnalyticsData([])
                    // metricFBSales === 1 ? setFbAnalyticsData2(response.data[1].SecondResult.fbMetrics) : setFbAnalyticsData2([])
                    insertToLog(" generated Analytics")
                    setLoading(false)


                })
                .catch((error) => {
                    toast.error('Error');
                    setLoading(false)
                    console.log(error);
                });
        }
        else {

            const data = {
                query: {
                    concessionareId: concessionaire_id,
                    locations: [
                        {
                            type: selectedType,
                            values: selectedTypeIdArray
                        }
                    ],
                    metrics: [
                        {
                            trafficIn: 0,
                            trafficOut: 0,
                            sales: metricSales,
                            transCount: metricTransactionCount,
                            conversionRate: 0,
                            atv: metricATV,
                            fb: metricFBSales,
                            revenueShareAmount: metricRevenueShare,
                            revenueSharePercentage: metricRevenueSharePerc,
                            baseRent: metricBaseRent
                        }
                    ],
                    dateRanges: [
                        {
                            start: startDate,
                            finish: endDate
                        }
                    ],
                    timeRanges: [
                        {
                            type: "store_hours"
                        }
                    ],
                    groupBy: [
                        groupBy
                    ]
                }

            };
            axios
                .post(`${config.hostname}/analytics/single`, data, configheader)
                .then((response) => {
                    setAnalyticsData(response.data.metrics)
                    metricFBSales === 1 ? setFbAnalyticsData(response.data.fbMetrics) : setFbAnalyticsData([])
                    insertToLog(" generated Analytics")
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error('Error');
                    setLoading(false)
                    console.log(error);
                });
        }


    }, [level, selectedTypeIdArray, metricSales, metricTransactionCount, metricATV, metricFBSales,
        metricRevenueShare, metricRevenueSharePerc, metricBaseRent, startDate, endDate, startDate2, endDate2, selectedGroupBy
    ]);

    useEffect(() => {
        generateReport();
    }, [generateReport]);

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Admin",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }



    const handleExport = (format) => {

        const metaData = [
            ['Date: ', startDate === endDate ? 'Date: ' + startDate : 'From: ' + startDate + ' To: ' + endDate],
            ['Time: ', selectedTime],
            ['Group By: ', selectedGroupBy]
        ];
        if (format === 'excel') {
            exportToExcel(metaData)
            insertToLog(" exported Analytics to Excel")
        }
        else if (format === 'pdf') {
            exportToPdf(metaData)
            insertToLog(" exported Analytics to pdf")
        }
    }

    const exportToExcel = (metaData) => {

        const wb = XLSX.utils.book_new(); // Create a new workbook
        const ws = XLSX.utils.aoa_to_sheet([]); // Create a new worksheet

        let rowOffset = 0;

        XLSX.utils.sheet_add_aoa(ws, [["Analytics Data"]], { origin: { r: rowOffset, c: 0 } });

        // Add Metadata
        if (metaData && metaData.length > 0) {
            const metaDataSheet = XLSX.utils.json_to_sheet(metaData);
            XLSX.utils.sheet_add_json(ws, metaData, { origin: -1, skipHeader: true });
            rowOffset = Math.max(metaData.length + 2, rowOffset); // Update rowOffset, add 2 for spacing
        }

        // Add Heading 'Analytics Data'

        // Merge cells for the 'Analytics Data' heading
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({ s: { r: rowOffset, c: 0 }, e: { r: rowOffset, c: 5 } });
        rowOffset++;

        // Add Analytics Data
        const analyticsHeaders = ['Category'];
        if (metricSales) analyticsHeaders.push('Sales');
        if (metricTransactionCount) analyticsHeaders.push('Transaction Count');
        if (metricRevenueShare) analyticsHeaders.push('Revenue Share Amount');
        if (metricRevenueSharePerc) analyticsHeaders.push('Revenue Share %');
        if (metricBaseRent) analyticsHeaders.push('Base Rent');

        const analyticsSheetData = analyticsData.map(item => {
            const row = [item.groupByValue];
            if (metricSales) row.push(item.sales);
            if (metricTransactionCount) row.push(item.transactionCount);
            if (metricRevenueShare) row.push(item.revenueShareAmount);
            if (metricRevenueSharePerc) row.push(item.revenueSharePercentage);
            if (metricBaseRent) row.push(item.baseRent);
            return row;
        });

        if (analyticsSheetData.length > 0) {
            XLSX.utils.sheet_add_aoa(ws, [analyticsHeaders], { origin: { r: rowOffset, c: 0 } });
            rowOffset++;
            XLSX.utils.sheet_add_aoa(ws, analyticsSheetData, { origin: { r: rowOffset, c: 0 } });
            rowOffset += analyticsSheetData.length + 1;
        }

        // Add FB Analytics Data
        if (fbAnalyticsData && fbAnalyticsData.length > 0) {
            XLSX.utils.sheet_add_aoa(ws, [['Delivery Partner Name', 'Sales']], { origin: { r: rowOffset, c: 0 } });
            rowOffset++;
            const fbAnalyticsSheetData = fbAnalyticsData.map(item => [item.partnerName, item.sales]);
            XLSX.utils.sheet_add_aoa(ws, fbAnalyticsSheetData, { origin: { r: rowOffset, c: 0 } });
        }

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Analytics');
        // Save the workbook
        XLSX.writeFile(wb, 'Analytics.xlsx');
    }

    const exportToPdf = (metaData) => {
        const doc = new jsPDF();

        let startY = 20;

        // Add Metadata
        doc.text('Analytics Data', 14, startY);
        if (metaData && metaData.length > 0) {
            doc.autoTable({
                body: metaData.map(item => Object.values(item)),
                startY: startY + 10,
            });
            startY = doc.lastAutoTable.finalY + 10;
        }

        // Construct headers and data for Analytics Data based on metrics
        const analyticsHeaders = ['Category'];
        if (metricSales) analyticsHeaders.push('Sales');
        if (metricTransactionCount) analyticsHeaders.push('Transaction Count');
        if (metricRevenueShare) analyticsHeaders.push('Revenue Share Amount');
        if (metricRevenueSharePerc) analyticsHeaders.push('Revenue Share %');
        if (metricBaseRent) analyticsHeaders.push('Base Rent');

        const analyticsSheetData = analyticsData.map(item => {
            const row = [item.groupByValue];
            if (metricSales) row.push(item.sales);
            if (metricTransactionCount) row.push(item.transactionCount);
            if (metricRevenueShare) row.push(item.revenueShareAmount);
            if (metricRevenueSharePerc) row.push(item.revenueSharePercentage);
            if (metricBaseRent) row.push(item.baseRent);
            return row;
        });

        // Add Analytics Data
        if (analyticsSheetData && analyticsSheetData.length > 0) {
            if (startY + 20 > doc.internal.pageSize.height) {
                doc.addPage();
                startY = 20;
            }

            doc.autoTable({
                head: [analyticsHeaders],
                body: analyticsSheetData,
                startY: startY + 10,
            });
            startY = doc.lastAutoTable.finalY + 10;
        }

        // Add FB Analytics Data
        if (fbAnalyticsData && fbAnalyticsData.length > 0) {
            const fbAnalyticsSheetData = fbAnalyticsData.map(item => [item.partnerName, item.sales]);

            if (startY + 20 > doc.internal.pageSize.height) {
                doc.addPage();
                startY = 20;
            }

            doc.autoTable({
                head: [['Delivery Partner Name', 'Sales']],
                body: fbAnalyticsSheetData,
                startY: startY + 10,
            });
        }

        // Save the PDF
        doc.save('Analytics.pdf');
    }

    const isAnyLocationSelected = () => {
        return Object.values(checkedItems).some(checked => checked);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handlePageClick2 = (data) => {
        setCurrentPage2(data.selected);
    };

    const pageCount = Math.ceil(analyticsData.length / itemsPerPage);
    const currentPageData = analyticsData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const pageCount2 = Math.ceil(interleavedMetrics.length / itemsPerPage);
    const currentPageData2 = interleavedMetrics.slice(currentPage2 * itemsPerPage, (currentPage2 + 1) * itemsPerPage);

    const labels = analyticsData.map(item => item.groupByValue);

    const salesData = analyticsData.map(item => item.sales);
    const salesData2 = analyticsData2.map(item => item.sales);

    const transactionCountData = analyticsData.map(item => item.transactionCount);
    const transactionCountData2 = analyticsData2.map(item => item.transactionCount);

    const atvData = analyticsData.map(item => item.atv);
    const atvData2 = analyticsData2.map(item => item.atv);

    const revenueShareAmountData = analyticsData.map(item => item.revenueShareAmount);
    const revenueShareAmountData2 = analyticsData2.map(item => item.revenueShareAmount);

    const revenueSharePercentageData = analyticsData.map(item => item.revenueSharePercentage);
    const revenueSharePercentageData2 = analyticsData2.map(item => item.revenueSharePercentage);

    const baseRentData = analyticsData.map(item => item.baseRent);
    const baseRentData2 = analyticsData2.map(item => item.baseRent);

    const fbLabels = fbAnalyticsData.length > 0 ? fbAnalyticsData.map(item => item.partnerName) : []

    const fbChartData = fbAnalyticsData.length > 0 ? fbAnalyticsData.map(item => item.sales) : []
    const fbChartData2 = fbAnalyticsData2.length > 0 ? fbAnalyticsData.map(item => item.sales) : []

    const randomColorsFB = fbAnalyticsData.length > 0 ? labels.slice(1).map(() => getRandomColor()) : ''
    const randomColorsFB2 = fbAnalyticsData2.length > 0 ? labels.slice(1).map(() => getRandomColor()) : ''


    const interleaveData = (data1, data2) => {
        const maxLength = Math.max(data1.length, data2.length);
        const interleaved = [];

        for (let i = 0; i < maxLength; i++) {
            if (i < data1.length) {
                interleaved.push({ ...data1[i], color: 'black' });
            }
            if (i < data2.length) {
                interleaved.push({ ...data2[i], color: 'brown' });
            }
        }

        return interleaved;
    };



    const salesOptions = {

        series: [
            {
                name: 'Sales',
                data: salesData
            },
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
    };

    const salesOptions2 = {

        series: [
            {
                name: startDate + ' - ' + endDate,
                data: salesData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: salesData2
            }
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },
        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            //colors: ['transparent'],
        }
    };

    const transactionCountOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'Transaction Count',
            data: transactionCountData
        }],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],

    };

    const transactionCountOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: transactionCountData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: transactionCountData2
            },

        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],

        stroke: {
            show: true,
            width: 2,
            // colors: ['transparent']
        }
    };

    const atvOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'ATV',
            data: atvData
        }],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],

    };

    const atvOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: atvData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: atvData2
            }
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            // colors: ['transparent']
        }
    };

    const revenueShareAmountOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'Revenue Share Amount',
            data: revenueShareAmountData
        }],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],

    };

    const revenueShareAmountOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: revenueShareAmountData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: revenueShareAmountData2
            }
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            //colors: ['transparent']
        }
    };

    const revenueSharePercentageOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'Revenue Share Percentage',
            data: revenueSharePercentageData
        }],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
    };

    const revenueSharePercentageOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: revenueSharePercentageData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: revenueSharePercentageData2
            }
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            // colors: ['transparent']
        }
    };

    const baseRentOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'Base Rent',
            data: baseRentData
        }],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
    };

    const baseRentOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: baseRentData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: baseRentData2
            }
        ],
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            //colors: ['transparent']
        }
    };

    const FnbOptions = {
        chart: {
            type: 'bar'
        },
        series: [{
            name: 'Sales',
            data: fbChartData
        }],
        xaxis: {
            categories: fbLabels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
    };

    const FnbOptions2 = {
        chart: {
            type: 'bar'
        },
        series: [
            {
                name: startDate + ' - ' + endDate,
                data: fbChartData
            },
            {
                name: startDate2 + ' - ' + endDate2,
                data: fbChartData2
            }
        ],
        xaxis: {
            categories: fbLabels
        },
        dataLabels: {
            enabled: false
        },

        colors: [primary, '#4caaf0'],
        stroke: {
            show: true,
            width: 2,
            // colors: ['transparent']
        },
    };

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    const randomColors = labels.slice(1).map(() => getRandomColor());
    const colors = [primary, ...randomColors];


    const colorsFB = [primary, ...randomColorsFB];

    const apexPieChartSales = {
        series: salesData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartSales2 = {
        series: salesData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    // Pie Chart Data for Transaction Count
    const apexPieChartTransactionCount = {
        series: transactionCountData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartTransactionCount2 = {
        series: transactionCountData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    // Pie Chart Data for ATV (Average Transaction Value)
    const apexPieChartATV = {
        series: atvData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartATV2 = {
        series: atvData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    // Pie Chart Data for Revenue Share Amount
    const apexPieChartRevenueShareAmount = {
        series: revenueShareAmountData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartRevenueShareAmount2 = {
        series: revenueShareAmountData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartRevenueSharePercentage = {
        series: revenueSharePercentageData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartRevenueSharePercentage2 = {
        series: revenueSharePercentageData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartBaseRent = {
        series: baseRentData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartBaseRent2 = {
        series: baseRentData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartFB = {
        series: fbChartData,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: fbLabels,
            colors: colorsFB,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const apexPieChartFB2 = {
        series: fbChartData2,
        options: {
            chart: {
                width: 380,
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: fbLabels,
            colors: colorsFB,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const dateInputStyle = {
        height: '20px',
        width: '140px',
        fontSize: '14px'
    };

    const dateButtonStyle = {
        height: '25px',
        width: '25px',
        padding: '0',
        fontSize: '12px',
    };

    return (
        <Fragment>
            <Row>
                <Col xs={3}>
                    <Card>
                        <CardBody>
                            <div className="default-according" id="accordion">
                                <Card>
                                    <CardHeader className="bg-primary">
                                        <H5 attrH5={{ className: 'mb-0' }}>
                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggle(1) }}>
                                                {t('Visualization')}<span className="digits"></span>
                                            </Btn>
                                        </H5>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen[1]}>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="visualization"
                                                            value="Bar Chart"
                                                            checked={visualizationType === "Bar Chart"}
                                                            onChange={(e) => setVisualizationType(e.target.value)}
                                                        />{"Bar Chart"}
                                                    </label>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="visualization"
                                                            value="Line Chart"
                                                            checked={visualizationType === "Line Chart"}
                                                            onChange={(e) => setVisualizationType(e.target.value)}
                                                        />{"Line Chart"}
                                                    </label>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="visualization"
                                                            value="Pie Chart"
                                                            checked={visualizationType === "Pie Chart"}
                                                            onChange={(e) => setVisualizationType(e.target.value)}
                                                        />{"Pie Chart"}
                                                    </label>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="visualization"
                                                            value="Table"
                                                            checked={visualizationType === "Table"}
                                                            onChange={(e) => setVisualizationType(e.target.value)}
                                                        />{"Table"}
                                                    </label>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className="bg-primary">
                                        <H5 attrH5={{ className: 'mb-0' }}>
                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggle(2) }}>
                                                {t('Locations')}<span className="digits"></span>
                                            </Btn>
                                        </H5>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen[2]}>
                                        <CardBody>
                                            <Row>
                                                <Input

                                                    className='form-control mb-3'
                                                    type='search'
                                                    placeholder='Search Tenant'
                                                    onChange={handleSearchChange}
                                                    value={searchQuery}
                                                />
                                                {filteredLocations.map(location => (
                                                    <TreeNode
                                                        key={location.id}
                                                        node={location}
                                                        checkedItems={checkedItems}
                                                        onToggle={handleToggle}
                                                        onLevelChange={onLevelChange}
                                                        getSelectedLevel={getSelectedLevel}
                                                        onCheck={() => generateReport()}
                                                    />
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className="bg-primary">
                                        <H5 attrH5={{ className: 'mb-0' }}>
                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggle(3) }}>
                                                {t('Metrics')}<span className="digits"></span>
                                            </Btn>
                                        </H5>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen[3]}>
                                        <CardBody>
                                            <Card>
                                                <CardHeader>
                                                    <H5 attrH5={{ className: 'mb-0' }}>
                                                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link', color: 'default', onClick: () => toggleSubAccordion(6) }}>
                                                            {t('Sales')}<span className="digits"></span>
                                                        </Btn>
                                                    </H5>
                                                </CardHeader>
                                                <Collapse isOpen={subAccordionOpen[6]}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <Label className="d-block" for="chk-ani">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkSales"
                                                                        type="checkbox"
                                                                        checked={selectedMetrics.includes("Sales")}
                                                                        onChange={() => handleMetricToggle("Sales")}
                                                                    />{"Sales"}
                                                                </Label>
                                                                <Label className="d-block" for="chk-ani1">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkTransactionCount"
                                                                        type="checkbox"
                                                                        checked={selectedMetrics.includes("Transaction Count")}
                                                                        onChange={() => handleMetricToggle("Transaction Count")}
                                                                    />{"Transaction Count"}
                                                                </Label>
                                                                <Label className="d-block" for="chk-ani2">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkAtv"
                                                                        type="checkbox"
                                                                        checked={selectedMetrics.includes("ATV")}
                                                                        onChange={() => handleMetricToggle("ATV")}
                                                                    />{"ATV"}
                                                                </Label>
                                                                <Label className="d-block" for="chk-ani3">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkF&BSales"
                                                                        type="checkbox"
                                                                        checked={selectedMetrics.includes("F&B Sales")}
                                                                        onChange={() => handleMetricToggle("F&B Sales")}
                                                                    />{"F&B Sales"}
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <H5 attrH5={{ className: 'mb-0' }}>
                                                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link', color: 'default', onClick: () => toggleSubAccordion(7) }}>
                                                            {t('Revenue')}<span className="digits"></span>
                                                        </Btn>
                                                    </H5>
                                                </CardHeader>
                                                <Collapse isOpen={subAccordionOpen[7]}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <Label className="d-block" for="chk-ani">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkRevenueShare"
                                                                        type="checkbox"
                                                                        checked={selectedRevenue.includes("Revenue Share Amount")}
                                                                        onChange={() => handleRevenueToggle("Revenue Share Amount")}
                                                                    />{"Revenue Share Amount"}
                                                                </Label>
                                                                <Label className="d-block" for="chk-ani1">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkRevenuePercentage"
                                                                        type="checkbox"
                                                                        checked={selectedRevenue.includes("Revenue Share %")}
                                                                        onChange={() => handleRevenueToggle("Revenue Share %")}
                                                                    />{"Revenue Share %"}
                                                                </Label>
                                                                <Label className="d-block" for="chk-ani2">
                                                                    <Input
                                                                        className="checkbox_animated"
                                                                        id="chkBaseRent"
                                                                        type="checkbox"
                                                                        checked={selectedRevenue.includes("Base Rent")}
                                                                        onChange={() => handleRevenueToggle("Base Rent")}
                                                                    />{"Base Rent"}
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className="bg-primary">
                                        <H5 attrH5={{ className: 'mb-0' }}>
                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggle(4) }}>
                                                {t('Date Range')}<span className="digits"></span>
                                            </Btn>
                                        </H5>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen[4]}>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="dateType"
                                                            value="custom"
                                                            checked={dateType === "custom"}
                                                            onChange={(e) => setDateType(e.target.value)}
                                                        />{"Custom Date"}
                                                    </label>
                                                    <label className="d-block">
                                                        <input
                                                            className="radio_animated"
                                                            type="radio"
                                                            name="dateType"
                                                            value="default"
                                                            checked={dateType === "default"}
                                                            onChange={(e) => setDateType(e.target.value)}
                                                        />{"Default Date"}
                                                    </label>
                                                </Col>
                                            </Row>
                                            {dateType === "custom" ? (
                                                <Row>
                                                    <Row className="align-items-center mb-3">
                                                        <Col xs="auto">
                                                            <Label>Start Date</Label>
                                                        </Col>
                                                        <Col xs="auto" className="align-items-center mb-3">
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                onClick={toggleAdditionalDateRange}
                                                                style={dateButtonStyle}
                                                            >
                                                                {showAdditionalDateRange ? '-' : '+'}
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                className="form-control form-control-primary btn-square"
                                                                type="date"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                style={dateInputStyle}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Label>End Date</Label>
                                                            <Input
                                                                className="form-control form-control-primary btn-square mb-3"
                                                                type="date"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                style={dateInputStyle}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {showAdditionalDateRange && (
                                                        <Row>
                                                            <Col xs="auto">
                                                                <Label>Start Date</Label>
                                                                <Input className="form-control form-control-primary btn-square mb-3"
                                                                    type="date"
                                                                    value={startDate2}
                                                                    onChange={(e) => setStartDate2(e.target.value)}
                                                                    style={dateInputStyle} />
                                                            </Col>
                                                            <Col xs="auto">
                                                                <Label>End Date</Label>
                                                                <Input className="form-control form-control-primary btn-square mb-3"
                                                                    type="date"
                                                                    value={endDate2}
                                                                    onChange={(e) => setEndDate2(e.target.value)}
                                                                    style={dateInputStyle} />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col>
                                                        <Input className="form-control form-control-primary btn-square mb-3" name="selecdatet" type="select"
                                                            style={{ height: '30px', width: '140px', fontSize: '14px' }}
                                                            onChange={handleDateSelectionchange}
                                                        >
                                                            <option value="">Select Default Date</option>
                                                            <option value="today">Today</option>
                                                            <option value="yesterday">Yesterday</option>
                                                            <option value="thisWeek">This week</option>
                                                            <option value="thisMonth">This Month</option>
                                                            <option value="thisYear">This Year</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Input className="form-control form-control-primary btn-square" name="selecttime"
                                                type="select"
                                                style={{ height: '30px', width: '140px', fontSize: '14px' }}
                                                value={selectedTime}
                                                onChange={(e) => setSelectedTime(e.target.value)}>
                                                <option value="">Select Time</option>
                                                <option value="All Day">All Day</option>
                                                <option value="Store Hours">Store Hours</option>
                                            </Input>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className="bg-primary">
                                        <H5 attrH5={{ className: 'mb-0' }}>
                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: () => toggle(5) }}>
                                                {t('Group By')}<span className="digits"></span>
                                            </Btn>
                                        </H5>
                                    </CardHeader>
                                    <Collapse isOpen={isOpen[5]} >
                                        <CardBody>
                                            <Input
                                                className="form-control form-control-primary btn-square"
                                                name="select"
                                                type="select"
                                                style={{ height: '35px', width: '140px', fontSize: '14px' }}
                                                value={groupBy}
                                                onChange={handleGroupByChange}
                                            >
                                                <option value="location">Location</option>
                                                <option value="date">Date</option>
                                                <option value="tenantGroup">Tenant Group</option>
                                                <option value="property">Property</option>
                                                <option value="category">Category</option>
                                                <option value="subcategory">Sub category</option>
                                                <option value="floor">Floor</option>
                                                {/* <option value="entrance">Entrance</option> */}
                                            </Input>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={9}>
                    <Card>
                        <CardBody>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                <Button size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {visualizationType}
                                </Button>

                                {selectedMetrics.length > 0 &&
                                    (
                                        selectedMetrics.map((metric, index) => (
                                            <Button key={index} size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {metric}
                                                <X size={14} style={{ marginLeft: '5px' }} onClick={() => handleRemoveMetric(metric)} />
                                            </Button>
                                        ))
                                    )}

                                {selectedRevenue.length > 0 && (
                                    selectedRevenue.map((revenue, index) => (
                                        <Button key={index} size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {revenue}
                                            <X size={14} style={{ marginLeft: '5px' }} onClick={() => handleRemoveRevenue(revenue)} />
                                        </Button>
                                    ))
                                )}

                                <Button size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {showAdditionalDateRange ? 'Date Range1:' : 'Date Range:'}
                                    {startDate && endDate ? ` ${startDate} to ${endDate}` : ' No Date Range Selected'}
                                </Button>

                                {showAdditionalDateRange && (
                                    <Button size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        Date Range2:
                                        {startDate2 && endDate2 && ` ${startDate2} to ${endDate2}`}
                                        <X size={14} style={{ marginLeft: '5px' }} onClick={() => handleRemove('dateRange2')} />
                                    </Button>
                                )}

                                <Button size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    Time: {selectedTime}
                                </Button>

                                <Button size="sm" color="primary" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    Group By: {selectedGroupBy}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                    {loading === true ? (
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-3' }} />
                        </div>
                    ) : (
                        <Row>
                            {visualizationType === "Table" && isAnyLocationSelected() && (
                                <Row>
                                    {showAdditionalDateRange && startDate2 !== '' && endDate2 !== '' ? (
                                        <Row>
                                            <div className='mb-3'>
                                                <Label> {'Date Range1: ' + startDate + '-' + endDate}</Label>
                                            </div>
                                            <div className='mb-3'>
                                                <Label style={{ color: 'brown' }}>{'Date Range2: ' + startDate2 + '-' + endDate2}</Label>
                                            </div>
                                            <Card>
                                                <div className="table-responsive">
                                                    <Table >
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" >Category</th>
                                                                {metricSales === 1 && (
                                                                    <th scope="col" >Sales</th>
                                                                )}
                                                                {metricTransactionCount === 1 && (
                                                                    <th scope="col" >Transaction Count</th>
                                                                )}
                                                                {metricATV === 1 && (
                                                                    <th scope="col" >ATV</th>
                                                                )}
                                                                {metricRevenueShare === 1 && (
                                                                    <th scope="col" >Revenue Share Amount</th>
                                                                )}
                                                                {metricRevenueSharePerc === 1 && (
                                                                    <th scope="col" >Revenue Share %</th>
                                                                )}
                                                                {metricBaseRent === 1 && (
                                                                    <th scope="col" >Base Rent</th>
                                                                )}
                                                                <th scope="col">
                                                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                                        <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                                                            <i className="fa fa-ellipsis-v" style={{ cursor: 'pointer' }}></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu end>
                                                                            <DropdownItem onClick={() => handleExport('excel')}>Export to Excel</DropdownItem>
                                                                            <DropdownItem onClick={() => handleExport('pdf')}>Export to PDF</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Array.isArray(interleavedMetrics) && interleavedMetrics.length > 0 ? (
                                                                currentPageData2.map((item, index) => (
                                                                    <tr key={index} style={{ color: item.color }}>
                                                                        <td style={{ color: item.color }} >{item.groupByValue}</td>
                                                                        {metricSales === 1 && (
                                                                            <td style={{ color: item.color }}>{item.sales.toLocaleString()}</td>
                                                                        )}
                                                                        {metricTransactionCount === 1 && (
                                                                            <td style={{ color: item.color }}>{item.transactionCount.toLocaleString()}</td>
                                                                        )}
                                                                        {metricATV === 1 && (
                                                                            <td style={{ color: item.color }}>{item.atv.toLocaleString()}</td>
                                                                        )}
                                                                        {metricRevenueShare === 1 && (
                                                                            <td style={{ color: item.color }}>{item.revenueShareAmount.toLocaleString()}</td>
                                                                        )}
                                                                        {metricRevenueSharePerc === 1 && (
                                                                            <td style={{ color: item.color }}>{item.revenueSharePercentage}</td>
                                                                        )}
                                                                        {metricBaseRent === 1 && (
                                                                            <td style={{ color: item.color }}>{item.baseRent.toLocaleString()}</td>
                                                                        )}
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="9">No data available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {interleavedMetrics.length > 10 && (
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={pageCount2}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick2}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                )}
                                            </Card>
                                        </Row>
                                    ) : (
                                        <Card>
                                            <div className="table-responsive">

                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" >Category</th>
                                                            {metricSales === 1 && (
                                                                <th scope="col" >Sales</th>
                                                            )}
                                                            {metricTransactionCount === 1 && (
                                                                <th scope="col" >Transaction Count</th>
                                                            )}
                                                            {metricATV === 1 && (
                                                                <th scope="col" >ATV</th>
                                                            )}
                                                            {metricRevenueShare === 1 && (
                                                                <th scope="col" >Revenue Share Amount</th>
                                                            )}
                                                            {metricRevenueSharePerc === 1 && (
                                                                <th scope="col" >Revenue Share %</th>
                                                            )}
                                                            {metricBaseRent === 1 && (
                                                                <th scope="col" >Base Rent</th>
                                                            )}
                                                            <th scope="col">
                                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                                                        <i className="fa fa-ellipsis-v" style={{ cursor: 'pointer' }}></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end>
                                                                        <DropdownItem onClick={() => handleExport('excel')}>Export to Excel</DropdownItem>
                                                                        <DropdownItem onClick={() => handleExport('pdf')}>Export to PDF</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(analyticsData) && analyticsData.length > 0 ? (
                                                            currentPageData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.groupByValue}</td>
                                                                    {metricSales === 1 && (
                                                                        <td>{item.sales.toLocaleString()}</td>
                                                                    )}
                                                                    {metricTransactionCount === 1 && (
                                                                        <td>{item.transactionCount.toLocaleString()}</td>
                                                                    )}
                                                                    {metricATV === 1 && (
                                                                        <td>{item.atv.toLocaleString()}</td>
                                                                    )}
                                                                    {metricRevenueShare === 1 && (
                                                                        <td>{item.revenueShareAmount.toLocaleString()}</td>
                                                                    )}
                                                                    {metricRevenueSharePerc === 1 && (
                                                                        <td>{item.revenueSharePercentage}</td>
                                                                    )}
                                                                    {metricBaseRent === 1 && (
                                                                        <td>{item.baseRent.toLocaleString()}</td>
                                                                    )}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            {analyticsData.length > 10 && (
                                                <ReactPaginate
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                />
                                            )}
                                        </Card>
                                    )}
                                </Row>
                            )}

                            {visualizationType === "Table" && isAnyLocationSelected() && metricFBSales === 1 && (
                                <Row>
                                    {showAdditionalDateRange && startDate2 !== '' && endDate2 !== '' ? (
                                        <Card>
                                            <div className="table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" >Delivery Partner Name</th>
                                                            <th scope="col" >Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(interleavedFBMetrics) && interleavedFBMetrics.length > 0 ? (
                                                            interleavedFBMetrics.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ color: item.color }}>{item.partnerName}</td>
                                                                    <td style={{ color: item.color }}>{item.sales}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="6">Total</td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>
                                        </Card>
                                    ) : (
                                        <Card>
                                            <div className="table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" >Delivery Partner Name</th>
                                                            <th scope="col" >Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(fbAnalyticsData) && fbAnalyticsData.length > 0 ? (
                                                            fbAnalyticsData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td >{item.partnerName}</td>
                                                                    <td >{item.sales}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="6">Total</td>


                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>
                                        </Card>
                                    )}
                                </Row>
                            )}
                            {visualizationType === "Bar Chart" && isAnyLocationSelected() && (
                                <Card>
                                    {showAdditionalDateRange && startDate2 !== '' && endDate2 !== '' ? (
                                        <Row>
                                            <Card>
                                                <div>
                                                    {metricSales === 1 && (
                                                        <div id="sales-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                            <ReactApexChart options={salesOptions2} series={salesOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricTransactionCount === 1 && (
                                                        <div id="transaction-count-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                            <ReactApexChart options={transactionCountOptions2} series={transactionCountOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricATV === 1 && (
                                                        <div id="atv-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                            <ReactApexChart options={atvOptions2} series={atvOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricRevenueShare === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                            <ReactApexChart options={revenueShareAmountOptions2} series={revenueShareAmountOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricRevenueSharePerc === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                            <ReactApexChart options={revenueSharePercentageOptions2} series={revenueSharePercentageOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricBaseRent === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Base Rent</H5>
                                                            <ReactApexChart options={baseRentOptions2} series={baseRentOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                    {metricFBSales === 1 && (
                                                        <div id="fnb-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                            <ReactApexChart options={FnbOptions2} series={FnbOptions2.series} type="bar" height={350} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                        </Row>
                                    ) : (
                                        <Card>
                                            <div>
                                                {metricSales === 1 && (
                                                    <div id="sales-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                        <ReactApexChart options={salesOptions} series={salesOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricTransactionCount === 1 && (
                                                    <div id="transaction-count-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                        <ReactApexChart options={transactionCountOptions} series={transactionCountOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricATV === 1 && (
                                                    <div id="atv-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                        <ReactApexChart options={atvOptions} series={atvOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueShare === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <ReactApexChart options={revenueShareAmountOptions} series={revenueShareAmountOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueSharePerc === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                        <ReactApexChart options={revenueSharePercentageOptions} series={revenueSharePercentageOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricBaseRent === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Base Rent</H5>
                                                        <ReactApexChart options={baseRentOptions} series={baseRentOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                                {metricFBSales === 1 && (
                                                    <div id="fnb-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                        <ReactApexChart options={FnbOptions} series={FnbOptions.series} type="bar" height={350} />
                                                    </div>
                                                )}
                                            </div>
                                        </Card>
                                    )}
                                </Card>
                            )}
                            {visualizationType === "Line Chart" && isAnyLocationSelected() && (
                                <Card>
                                    {showAdditionalDateRange && startDate2 !== '' && endDate2 !== '' ? (
                                        <Row>
                                            <Card>
                                                <div>
                                                    {metricSales === 1 && (
                                                        <div id="sales-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                            <ReactApexChart options={salesOptions2} series={salesOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricTransactionCount === 1 && (
                                                        <div id="transaction-count-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                            <ReactApexChart options={transactionCountOptions2} series={transactionCountOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricATV === 1 && (
                                                        <div id="atv-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                            <ReactApexChart options={atvOptions2} series={atvOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricRevenueShare === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                            <ReactApexChart options={revenueShareAmountOptions2} series={revenueShareAmountOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricRevenueSharePerc === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                            <ReactApexChart options={revenueSharePercentageOptions2} series={revenueSharePercentageOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricBaseRent === 1 && (
                                                        <div id="revenue-share-amount-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Base Rent</H5>
                                                            <ReactApexChart options={baseRentOptions2} series={baseRentOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                    {metricFBSales === 1 && (
                                                        <div id="fnb-chart">
                                                            <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                            <ReactApexChart options={FnbOptions2} series={FnbOptions2.series} type="line" height={350} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                        </Row>
                                    ) : (

                                        <Card>
                                            <div>
                                                {metricSales === 1 && (
                                                    <div id="sales-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                        <ReactApexChart options={salesOptions} series={salesOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricTransactionCount === 1 && (
                                                    <div id="transaction-count-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                        <ReactApexChart options={transactionCountOptions} series={transactionCountOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricATV === 1 && (
                                                    <div id="atv-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                        <ReactApexChart options={atvOptions} series={atvOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueShare === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <ReactApexChart options={revenueShareAmountOptions} series={revenueShareAmountOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueSharePerc === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                        <ReactApexChart options={revenueSharePercentageOptions} series={revenueSharePercentageOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricBaseRent === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Base Rent</H5>
                                                        <ReactApexChart options={baseRentOptions} series={baseRentOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                                {metricFBSales === 1 && (
                                                    <div id="fnb-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                        <ReactApexChart options={FnbOptions} series={FnbOptions.series} type="line" height={350} />
                                                    </div>
                                                )}
                                            </div>
                                        </Card>
                                    )}
                                </Card>
                            )}
                            {visualizationType === "Pie Chart" && isAnyLocationSelected() && (
                                <Row>
                                    {showAdditionalDateRange && startDate2 !== '' && endDate2 !== '' ? (
                                        <Card>
                                            <div>
                                                {metricSales === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                        <Col id="sales-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartSales.options} series={apexPieChartSales.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="sales-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartSales2.options} series={apexPieChartSales2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricTransactionCount === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                        <Col id="transaction-count-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartTransactionCount.options} series={apexPieChartTransactionCount.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="transaction-count-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartTransactionCount2.options} series={apexPieChartTransactionCount2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricATV === 1 && (

                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                        <Col id="atv-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartATV.options} series={apexPieChartATV.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="atv-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartATV2.options} series={apexPieChartATV2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricRevenueShare === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartRevenueShareAmount.options} series={apexPieChartRevenueShareAmount.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartRevenueShareAmount2.options} series={apexPieChartRevenueShareAmount2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricRevenueSharePerc === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartRevenueSharePercentage.options} series={apexPieChartRevenueSharePercentage.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartRevenueSharePercentage2.options} series={apexPieChartRevenueSharePercentage2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricBaseRent === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartBaseRent.options} series={apexPieChartBaseRent.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartBaseRent2.options} series={apexPieChartBaseRent2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {metricFBSales === 1 && (
                                                    <Row>
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate + ' - ' + endDate}</div>
                                                            <ReactApexChart options={apexPieChartFB.options} series={apexPieChartFB.series} type="pie" height={350} />
                                                        </Col>
                                                        <Col id="revenue-share-amount-chart">
                                                            <div style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'normal', marginBottom: '1rem', marginTop: '1rem' }}>    {startDate2 + ' - ' + endDate2}</div>
                                                            <ReactApexChart options={apexPieChartFB2.options} series={apexPieChartFB2.series} type="pie" height={350} />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </Card>
                                    ) : (
                                        <Card>
                                            <div>
                                                {metricSales === 1 && (
                                                    <div id="sales-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Sales</H5>
                                                        <ReactApexChart options={apexPieChartSales.options} series={apexPieChartSales.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricTransactionCount === 1 && (
                                                    <div id="transaction-count-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Transaction Count</H5>
                                                        <ReactApexChart options={apexPieChartTransactionCount.options} series={apexPieChartTransactionCount.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricATV === 1 && (
                                                    <div id="atv-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>ATV</H5>
                                                        <ReactApexChart options={apexPieChartATV.options} series={apexPieChartATV.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueShare === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <ReactApexChart options={apexPieChartRevenueShareAmount.options} series={apexPieChartRevenueShareAmount.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricRevenueSharePerc === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Percentage</H5>
                                                        <ReactApexChart options={apexPieChartRevenueSharePercentage.options} series={apexPieChartRevenueSharePercentage.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricBaseRent === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Revenue Share Amount</H5>
                                                        <ReactApexChart options={apexPieChartBaseRent.options} series={apexPieChartBaseRent.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                                {metricFBSales === 1 && (
                                                    <div id="revenue-share-amount-chart">
                                                        <H5 attrH5={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>F&B Sales</H5>
                                                        <ReactApexChart options={apexPieChartFB.options} series={apexPieChartFB.series} type="pie" height={350} />
                                                    </div>
                                                )}
                                            </div>
                                        </Card>
                                    )}
                                </Row>
                            )}


                            {!isAnyLocationSelected() && (
                                <Card>
                                    <H6 attrH6={{ className: 'card-title mb-3 mt-4 ', style: { marginLeft: '20px' } }}>Select Any Location</H6>
                                </Card>
                            )}
                        </Row>
                    )}
                </Col>
            </Row >
        </Fragment >
    );
};

export default AnalyticsView;



