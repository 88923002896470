import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Btn, H4 } from '../../../../AbstractElements';
import { Card, CardBody, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, CardHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

const MonthwiseSalesView = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const currentYear = new Date().getFullYear();
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [year, setYear] = useState(currentYear);
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [group_name, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_mall_code, setPropertyMallCode] = useState('');
    const [property_id, setPropertyId] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [reportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const token = localStorage.getItem("sessiontoken");
    const [tooltip, setTooltip] = useState('');
    const [itemsPerPage] = useState(50);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const [selectedTenants, setSelectedTenants] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);
    const [searchTenant, setSearchTenant] = useState('');

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const monthNames = [
        'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const filteredTenants = tenants.filter(tenant =>
        tenant.tenant_store_name.toLowerCase().includes(searchTenant.toLowerCase())
    );

    const handleYearChange = (e) => {
        setYear(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePropertyChange = (e) => {
        const selectedProperty = properties.find(property => property._id === e.target.value);
        setPropertyMallCode(selectedProperty ? selectedProperty.property_mall_code : '');
        setPropertyId(e.target.value);
        setTenantId('')
        setSelectedTenants([])
        setSelectedFloor('')
        setReportData([]);
        setCurrentPage(0);
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setSubcategoryId('')
        setTenantId('');
        setSelectedTenants([])
        setReportData([]);
        setCurrentPage(0);
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
        setSelectedTenants([])
    };

    const handleTenantChange = (e) => {
        // setTenantId(e.target.value);
        const value = e.target.value;
        if (selectedTenants.includes(value)) {
            setSelectedTenants(selectedTenants.filter(item => item !== value));
        } else {
            setSelectedTenants([...selectedTenants, value]);
        }

        setReportData([]);
        setCurrentPage(0);
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const generateReport = async (e) => {
        e.preventDefault();

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';


        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            year: year,
            group_name,
            category_name,
            property_name,
            subcategory_name,
            tenant_id: selectedTenants,
            floor: selectedFloor
        };

        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getmonthwisesalesreport`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                insertToLog(" generated Monthwise report")
                console.log(response.data);
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    //const offset = currentPage * itemsPerPage;


    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = key => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'asc') {
                return <FontAwesomeIcon icon={faSortUp} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
            if (sortConfig.direction === 'desc') {
                return <FontAwesomeIcon icon={faSortDown} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
        }

    };

    const groupedData = reportData.reduce((acc, item) => {
        const key = `${item.tenant_name}-${item.property_name}-${item.unit_code}-${item.tenant_code}`;
        if (!acc[key]) {
            acc[key] = {
                tenant_name: item.tenant_name,
                property_name: item.property_name,
                unit_code: item.unit_code,
                tenant_code: item.tenant_code,
                months: {}
            };
        }
        acc[key].months[item.month] = {
            total_net_sales: item.total_net_sales,
            total_transaction_count: item.total_transaction_count
        };
        return acc;
    }, {});

    const formattedData = Object.values(groupedData);

    const sortedReportData = React.useMemo(() => {
        let sortableData = [...formattedData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [reportData, sortConfig]);

    const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const pageCount = Math.ceil(sortedReportData.length / itemsPerPage);

    const exportToExcel = () => {

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';


        // Create the header row for filters
        const filterDetails = [
            ['Monthwise Sales Report'],
            [`Year: ${year}`],
            property_name && [`Property: ${property_name}`],
            category_name && [`Category: ${category_name}`],
            subcategory_name && [`Subcategory: ${subcategory_name}`],
            selectedFloor && [`Floor: ${selectedFloor}`],
            [] // Blank row for spacing
        ].filter(Boolean); // Remove undefined/null rows

        // Prepare table data for the Excel file
        const tableData = sortedReportData.map((tenant) => {
            const row = [
                tenant.tenant_name,
                tenant.property_name,
                tenant.unit_code,
                tenant.tenant_code
            ];

            monthNames.forEach((month) => {
                const value = tenant.months[month]?.total_net_sales || 0;
                const formattedValue = formatCurrency(value);
                row.push(formattedValue);
            });

            const total = monthNames.reduce((total, month) => total + (tenant.months[month]?.total_net_sales || 0), 0);
            const formattedTotal = formatCurrency(total);
            row.push(formattedTotal);
            return row;
        });

        // Column headers for the table
        const headers = [
            'Tenant Name',
            'Property',
            'Unit Code',
            'Tenant Code',
            ...monthNames,
            'Total'
        ];

        // Merge filter details, headers, and table data into one worksheet
        const worksheetData = [
            ...filterDetails,
            headers,
            ...tableData
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // Create worksheet from 2D array
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");

        XLSX.writeFile(workbook, "Monthwise_Sales_Report.xlsx");
    };

    const exportToPDF = () => {

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';

        const doc = new jsPDF({ orientation: 'landscape' });

        // Add report heading
        doc.setFontSize(14);
        doc.text("Monthwise Sales Report", 14, 15);

        doc.setFontSize(10);
        let currentY = 25; // Starting Y position for the filter details

        // Conditionally add filters if they have a value
        year && (() => {
            doc.text(`Year: ${year}`, 14, currentY);
            currentY += 7;
        })();

        property_name && (() => {
            doc.text(`Property: ${property_name}`, 14, currentY);
            currentY += 7;
        })();

        category_name && (() => {
            doc.text(`Category: ${category_name}`, 14, currentY);
            currentY += 7;
        })();

        subcategory_name && (() => {
            doc.text(`Subcategory: ${subcategory_name}`, 14, currentY);
            currentY += 7;
        })();

        selectedFloor && (() => {
            doc.text(`Floor: ${selectedFloor}`, 14, currentY);
            currentY += 7;
        })();

        // Create table
        const columns = [
            "Tenant Name",
            "Property",
            "Unit Code",
            "Tenant Code",
            ...monthNames,
            "Total"
        ];

        const rows = sortedReportData.map((tenant) => {
            const row = [
                tenant.tenant_name,
                tenant.property_name,
                tenant.unit_code,
                tenant.tenant_code
            ];

            monthNames.forEach((month) => {
                const value = tenant.months[month]?.total_net_sales || 0;
                const formattedValue = formatCurrency(value);
                row.push(formattedValue);
            });

            const total = monthNames.reduce((total, month) => total + (tenant.months[month]?.total_net_sales || 0), 0);
            const formattedTotal = formatCurrency(total);
            row.push(formattedTotal);
            return row;
        });

        // Configure the table and set width for the 'Total' column
        doc.autoTable({
            head: [columns],
            body: rows,
            startY: currentY + 5, // Start below the filter details
            styles: { fontSize: 8, halign: 'center' },
            theme: 'striped',
            columnStyles: {
                [columns.length - 1]: {
                    cellWidth: 25 // Increase width of 'Total' column
                }
            }
        });

        doc.save("Monthwise_Sales_Report.pdf");
    };

    const formatCurrency = (value) => {
        if (typeof value !== 'number') return '-';
        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const reset = () => {
        setYear(currentYear)
        setPropertyId('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setSelectedTenants([])
        setSelectedFloor('');
        setReportData([]);
        setCurrentPage(0);

    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="year">Year</Label>
                                    <select name="year" id="year" className="form-control digits"
                                        value={year} onChange={handleYearChange}>
                                        <option value={currentYear}>{currentYear}</option>
                                        <option value={currentYear - 1}>{currentYear - 1}</option>
                                        <option value={currentYear - 2}>{currentYear - 2}</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="category_id">Category</Label>
                                    <select name="category_id" id="category_id" className="form-control"
                                        value={category_id} onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            {/* <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col> */}
                            {/* <Col >
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <label>Tenants</label>
                                    <div
                                        onClick={toggleDropdown}
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>Select Tenants</div>
                                        <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                    </div>
                                    {dropdownOpen && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '100%',
                                                left: 0,
                                                width: '100%',
                                                backgroundColor: '#fff',
                                                // border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginTop: '4px',
                                                zIndex: 1,
                                                // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            {tenants.map(tenant => (
                                                <label key={tenant._id} style={{ display: 'block', padding: '2px', cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        value={tenant._id}
                                                        checked={selectedTenants.includes(tenant._id)}
                                                        onChange={handleTenantChange}
                                                        style={{ marginRight: '8px' }}
                                                    />
                                                    {tenant.tenant_store_name}
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </Col> */}
                            <Col>
                                <div style={{ position: 'relative', width: '100%' }} ref={dropdownRef}>
                                    <label>Tenants</label>
                                    <div
                                        onClick={toggleDropdown}
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>Select Tenants</div>
                                        <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                    </div>
                                    {dropdownOpen && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '100%',
                                                left: 0,
                                                width: '100%',
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginTop: '4px',
                                                zIndex: 1,
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                maxHeight: '250px', // Set the maximum height for the dropdown
                                                overflowY: 'auto' // Scrollable content
                                            }}
                                        >
                                            {/* Search Bar */}
                                            <div style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search tenants..."
                                                    value={searchTenant}
                                                    onChange={(e) => setSearchTenant(e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        padding: '6px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px'
                                                    }}
                                                />
                                            </div>

                                            {/* Filtered Tenants List */}
                                            {filteredTenants.length > 0 ? (
                                                filteredTenants.map(tenant => (
                                                    <label
                                                        key={tenant._id}
                                                        style={{ display: 'block', padding: '8px', cursor: 'pointer' }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            value={tenant._id}
                                                            checked={selectedTenants.includes(tenant._id)}
                                                            onChange={handleTenantChange}
                                                            style={{ marginRight: '8px' }}
                                                        />
                                                        {tenant.tenant_store_name}
                                                    </label>
                                                ))
                                            ) : (
                                                <div style={{ padding: '8px', color: 'gray', textAlign: 'center' }}>
                                                    No results found
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Floor</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={selectedFloor} onChange={handleFloorChange}>
                                        <option value="">All</option>
                                        {floors.map((floor, index) => (
                                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    {loading === true ? (
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-3' }} />
                        </div>

                    ) : (
                        <Card>
                            <CardHeader>
                                <Row className="justify-content-end">
                                    <Col xs="auto" style={{ position: 'relative' }}>
                                        <Btn
                                            attrBtn={{
                                                color: 'primary',
                                                type: 'button',
                                                onClick: () => exportToExcel(),
                                                onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                                onMouseLeave: handleMouseLeave
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                        </Btn>
                                        {tooltip === 'Export to Excel' && (
                                            <div style={{
                                                position: 'absolute',
                                                top: '-30px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: 'black',
                                                color: 'white',
                                                padding: '5px 10px',
                                                borderRadius: '3px',
                                                whiteSpace: 'nowrap',
                                                fontSize: '12px',
                                                zIndex: 1,
                                            }}>
                                                {tooltip}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs="auto" style={{ position: 'relative' }}>
                                        <Btn
                                            attrBtn={{
                                                color: 'primary',
                                                type: 'button',
                                                onClick: () => exportToPDF(),
                                                onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                                onMouseLeave: handleMouseLeave
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                        </Btn>
                                        {tooltip === 'Export to PDF' && (
                                            <div style={{
                                                position: 'absolute',
                                                top: '-30px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                backgroundColor: 'black',
                                                color: 'white',
                                                padding: '5px 10px',
                                                borderRadius: '3px',
                                                whiteSpace: 'nowrap',
                                                fontSize: '12px',
                                                zIndex: 1,
                                            }}>
                                                {tooltip}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Col sm="12" lg="12" xl="12">
                                    <div className="table-responsive" >
                                        <Table>
                                            <thead >
                                                <tr>
                                                    <th>Tenant Name</th>
                                                    <th>Property</th>
                                                    <th>Unit Code</th>
                                                    <th>Tenant Code</th>
                                                    {monthNames.map((month) => (
                                                        <th key={month}>{month}</th>
                                                    ))}
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {currentPageData.map((tenant, index) => {
                                                    // Calculate total net sales for the tenant
                                                    const totalNetSales = monthNames.reduce((total, month) => {
                                                        return total + (tenant.months[month]?.total_net_sales || 0);
                                                    }, 0);

                                                    return (
                                                        <tr key={index}>
                                                            <td>{tenant.tenant_name}</td>
                                                            <td>{tenant.property_name}</td>
                                                            <td>{tenant.unit_code}</td>
                                                            <td>{tenant.tenant_code}</td>
                                                            {monthNames.map((month) => {
                                                                const monthData = tenant.months[month];
                                                                return (
                                                                    <td key={month} style={{ textAlign: 'center' }}>
                                                                        {monthData ? monthData.total_net_sales.toLocaleString() : '-'}
                                                                    </td>
                                                                );
                                                            })}
                                                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                {totalNetSales.toLocaleString()}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {reportData.length > 50 && (
                                        <ReactPaginate
                                            previousLabel={'<<'}
                                            nextLabel={'>>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                        />
                                    )}
                                </Col>
                            </CardBody>
                        </Card>
                    )}
                </Card>
            </Form>
        </Fragment>
    )
}

export default MonthwiseSalesView