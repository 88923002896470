export const MenuAdmin = [

  {
    menutitle: "DashBoard",
    menucontent: "Ready to use Apps",
    Items: [
      { path: `${process.env.PUBLIC_URL}/admin/dashboard/sales`, icon: "charts", title: "Sales", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/dashboard/tenant`, icon: "charts", title: "Tenant", type: "link" },

    ],
  },
  {
    menutitle: "Analytics",
    menucontent: "Ready to use Apps",
    Items: [

      { path: `${process.env.PUBLIC_URL}/admin/analytics`, icon: "knowledgebase", title: "Analytics", type: "link" },
    ],
  },

  {
    menutitle: "Reports",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Reports",
        icon: "table",
        type: "sub",
        badge: "badge badge-light-secondary",
        active: false,
        children: [

          { path: `${process.env.PUBLIC_URL}/admin/report/daywisereport`, icon: "file", title: "Daywise Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/daywiseconsolidated`, icon: "file", title: "Sales Summary Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/monthwisesales`, icon: "file", title: "Monthwise Sales Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/revenuesharereport`, icon: "file", title: "Revenue Share Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/tradingdensityreport`, icon: "file", title: "Trading Density Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/dailycomprehensiverevenue`, icon: "file", title: "Daily Comprehensive Revenue Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/growthanalysisreport`, icon: "file", title: "Growth Analysis Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/fbsalessummary`, icon: "file", title: "F&B Sales Summary", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/deliverypartnerreport`, icon: "file", title: "Sales Report-Delivery Partners", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/report/missingdates`, icon: "file", title: "Missing Dates Report", type: "link" },
          { path: `${process.env.PUBLIC_URL}/admin/dynamicreports`, icon: "file", title: "Dynamic Reports", type: "link" },

        ],
      },

    ]
  },

  {
    menutitle: "Data Grid",
    menucontent: "Ready to use Apps",
    Items: [

      { path: `${process.env.PUBLIC_URL}/admin/datagrid`, icon: "landing-page", title: "Data Grid", type: "link" },
    ],
  },

  {
    menutitle: "General Settings",
    menucontent: "Ready to use Apps",
    Items: [
      { path: `${process.env.PUBLIC_URL}/admin/country/viewcountry`, icon: "internationalization", title: "Country", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/property/viewproperty`, icon: "others", title: "Property", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/category/viewcategory`, icon: "animation", title: "Category", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/subcategory/viewsubcategory`, icon: "blog", title: "Subcategory", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/tenant/viewtenant`, icon: "widget", title: "Tenants", type: "link" },

    ],
  },

  {
    menutitle: "Configurations",
    menucontent: "Ready to use Apps",
    Items: [

      { path: `${process.env.PUBLIC_URL}/admin/configurations`, icon: "to-do", title: "Configurations", type: "link" },
    ],
  },

  {
    menutitle: "Admin Settings",
    menucontent: "Ready to use Apps",
    Items: [

      { path: `${process.env.PUBLIC_URL}/admin/userprofiles`, icon: "task", title: "User Profiles", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/users`, icon: "user", title: "Users", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/logs`, icon: "social", title: "Logs", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/schedulereports`, icon: "email", title: "Schedule Reports", type: "link" },
      { path: `${process.env.PUBLIC_URL}/admin/tenantportal`, icon: "email", title: "Tenant Portal", type: "link" },

    ],
  },

  {
    menutitle: "",
    menucontent: "Ready to use Apps",
    Items: [


    ],
  },

];
