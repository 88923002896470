// dashbaord
import Crypto from "../Components/Dashboard/Crypto";
import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import OnlineCourse from "../Components/Dashboard/OnlineCourse";
import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';
// //ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

// //Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// // Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

// //chart
import ApexCharts from "../Components/Charts/ApexCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";

// //forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";

import DataTables from "../Components/Tables/DataTable";

// //Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";

import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";

// //Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

// //Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'

// //Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

// //social app
import SocialAppContain from "../Components/Application/SocialApp";

// //calender
// import CalenderContain from '../Components/Application/Calender/Index';

// //Email
import MailInboxContain from "../Components/Application/Email/MailInbox";

// //File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";

// //E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

// //Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

// //Learning
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";

// //Map
import GoogleMaps from "../Components/Map/GoogleMap";
import PigeonMap from "../Components/Map/Pigeon Map";

// //Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";

//Dashboard Super admin
import DashboardSAdmin from "../Components/ControlPanel/Dashboard/Default";

//Admin Dashboard
import DashboardAdmin from "../Components/Admin/Dashboard/Default";
import TenantDashboard from "../Components/Admin/Dashboard/TenantDashboard";

//Concessionaire
import AddNewConcessionaire from "../Components/ControlPanel/Concessionaire/AddNewConcessionaire";
import ConcessionaireList from "../Components/ControlPanel/Concessionaire/ConcessionaireList";
import ViewConcessionaire from "../Components/ControlPanel/Concessionaire/ConcessionaireDetail";
import EditConcessionaire from "../Components/ControlPanel/Concessionaire/EditConcessionaire";

//Sadmin settings
import SadminUsers from "../Components/ControlPanel/Settings/Users";
import BackendUserHome from "../Components/BackendUser/Home";
import ViewAPIKey from "../Components/BackendUser/VIewAPIKey";
import SadminLog from "../Components/ControlPanel/Settings/Log";
import ErrorLog from "../Components/ControlPanel/Settings/ErrorLogs";
import TenantPortal from "../Components/Admin/AdminSettings/TenantPortal";

//Contry
import AddCountry from "../Components/Admin/Country/AddCountry";
import ViewCountry from "../Components/Admin/Country/ViewCountry";
import CountryView from "../Components/Admin/Country/CountryDetail";
import EditCountry from "../Components/Admin/Country/EditCountry";

//Property
import AddProperty from "../Components/Admin/Property/AddProperty";
import Properties from "../Components/Admin/Property/ViewProperty";
import PropertyDetails from "../Components/Admin/Property/PropertyDetail";
import EditProperty from "../Components/Admin/Property/EditProperty";

//Category
import AddCategory from "../Components/Admin/Category/AddCategory";
import ViewCategory from "../Components/Admin/Category/ViewCategory";
import EditCategory from "../Components/Admin/Category/EditCategory";

//Subcategory
import AddSubCategory from "../Components/Admin/SubCategory/AddSubCategory";
import ViewSubcategory from "../Components/Admin/SubCategory/ViewSubCategory";
import EditSubCategory from "../Components/Admin/SubCategory/EditSubCategory";

//Tenant
import ViewTenant from "../Components/Admin/Tenant/ViewTenant";
import TenantDetail from "../Components/Admin/Tenant/TenantDetail";
import EditTenant from "../Components/Admin/Tenant/EditTenant";
import AddNewTenant from "../Components/Admin/Tenant/AddTenant/AddNewTenant";
import TenantStatusChange from "../Components/Admin/Tenant/TenantStatus";

//Coonfigurations
import Configurations from "../Components/Admin/Configuration";


//Analytics
import Analytics from "../Components/Admin/Analytics";


//Reports
import DaywiseReport from "../Components/Admin/Reports/DaywiseReport";
import DaywiseConsolidated from "../Components/Admin/Reports/DaywiseConsolidated";
import RevenueShareReport from "../Components/Admin/Reports/RevenueShareReport";
import TradingDensityReport from "../Components/Admin/Reports/TradingDensityReport";
import DailyComprehensiveReport from "../Components/Admin/Reports/DailyComprehensiveReport";
import GrowthAnalysisReport from "../Components/Admin/Reports/GrowthAnalysisReport";
import DeliveryPartnerReport from "../Components/Admin/Reports/DeliveryPartnerReport";
import FbSalesSummary from "../Components/Admin/Reports/FBSalesSummary";
import MissingDatesReport from "../Components/Admin/Reports/MissingDatesReport";
import MonthwiseSalesReport from "../Components/Admin/Reports/MonthwiseSalesReport";


//Data Grid
import DataGrid from "../Components/Admin/DataGrid";

//Admin Settings
import UserProfile from "../Components/Admin/AdminSettings/UserProfile";
import Users from "../Components/Admin/AdminSettings/Users";
import Log from '../Components/Admin/AdminSettings/Log'

//Dynamic Reports
import DynamicReports from "../Components/Admin/DynamicReports";
import RunReport from "../Components/Admin/DynamicReports/RunReport";
import ScheduleReport from "../Components/Admin/ScheduleReport";

//change password
import ChangePassword from "../Auth/ChangePassword";

//Tenant login
import TenantHome from "../Components/Tenant/Home";
import ApiKey from "../Components/Tenant/APIKey";


export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component: <Default /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/e-commerce/:layout`, Component: <Ecommerce /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/online-course/:layout`, Component: <OnlineCourse /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/crypto/:layout`, Component: <Crypto /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/social/:layout`, Component: <Social /> },

  { path: `${process.env.PUBLIC_URL}/widgets/general/:layout`, Component: <General /> },
  { path: `${process.env.PUBLIC_URL}/widgets/chart/:layout`, Component: <Chart /> },

  { path: `${process.env.PUBLIC_URL}/ui-kits/typography/:layout`, Component: <Typography /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/:layout`, Component: <Avatars /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/helper-class/:layout`, Component: <HelperClasses /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grids/:layout`, Component: <Grid /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills/:layout`, Component: <TagAndPills /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/progress/:layout`, Component: <Progressbars /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal/:layout`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert/:layout`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/popover/:layout`, Component: <Popover /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/:layout`, Component: <Tooltips /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/spinner/:layout`, Component: <Spinners /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/:layout`, Component: <Dropdowns /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/accordion/:layout`, Component: <Accordian /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap/:layout`, Component: <TabBootstrap /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line/:layout`, Component: <TabLine /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/navs/:layout`, Component: <Navs /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/:layout`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/list/:layout`, Component: <List /> },

  { path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable/:layout`, Component: <Scrollable /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view/:layout`, Component: <TreeView /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify/:layout`, Component: <BootstrapNotify /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/rating/:layout`, Component: <RatingClass /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone/:layout`, Component: <Dropzones /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/tour/:layout`, Component: <Tours /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert/:layout`, Component: <SweetAlerts /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/carousel/:layout`, Component: <Carousels /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons/:layout`, Component: <Ribbons /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/pagination/:layout`, Component: <Paginations /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/steps/:layout`, Component: <Steps /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb/:layout`, Component: <BreadcrumbClass /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/rangeslider/:layout`, Component: <RangeSlider /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/imagecropper/:layout`, Component: <Imagecropper /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/stickynotes/:layout`, Component: <Sticky /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/drag_and_drop/:layout`, Component: <DragAndDrop /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload/:layout`, Component: <UploadImage /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards/:layout`, Component: <BasicCards /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards/:layout`, Component: <CreativeCards /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard/:layout`, Component: <TabCard /> },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1/:layout`, Component: <Timeline1 /> },

  { path: `${process.env.PUBLIC_URL}/icons/flag_icons/:layout`, Component: <FlagIcon /> },
  { path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon/:layout`, Component: <FontawesomeIcon /> },
  { path: `${process.env.PUBLIC_URL}/icons/ico_icon/:layout`, Component: <IcoIcon /> },
  { path: `${process.env.PUBLIC_URL}/icons/themify_icons/:layout`, Component: <ThemifyIcon /> },
  { path: `${process.env.PUBLIC_URL}/icons/feather_icons/:layout`, Component: <FeatherIcon /> },
  { path: `${process.env.PUBLIC_URL}/icons/weather_icons/:layout`, Component: <WeatherIcon /> },

  { path: `${process.env.PUBLIC_URL}/buttons/simplebutton/:layout`, Component: <DefaultButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/flat/:layout`, Component: <FlatButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/edge/:layout`, Component: <EdgeButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/raised/:layout`, Component: <RaisedButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/group/:layout`, Component: <GroupButton /> },

  { path: `${process.env.PUBLIC_URL}/charts/apex/:layout`, Component: <ApexCharts /> },
  { path: `${process.env.PUBLIC_URL}/charts/chartJs/:layout`, Component: <ChartJs /> },
  { path: `${process.env.PUBLIC_URL}/charts/google/:layout`, Component: <GoogleCharts /> },

  { path: `${process.env.PUBLIC_URL}/forms/controls/validation/:layout`, Component: <FormValidation /> },
  { path: `${process.env.PUBLIC_URL}/forms/controls/input/:layout`, Component: <BaseInput /> },
  { path: `${process.env.PUBLIC_URL}/forms/controls/radio-checkbox/:layout`, Component: <CheckboxandRadio /> },
  { path: `${process.env.PUBLIC_URL}/forms/controls/group/:layout`, Component: <InputGroups /> },
  { path: `${process.env.PUBLIC_URL}/forms/controls/megaoption/:layout`, Component: <MegaOption /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/datepicker/:layout`, Component: <Datepicker /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/typeahead/:layout`, Component: <TypeaheadComp /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/datetimepicker/:layout`, Component: <DateTimeContain /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/rangepicker/:layout`, Component: <FormDateRangepickerContain /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/select2/:layout`, Component: <FormSelectContain /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/switch/:layout`, Component: <FormSwitchContain /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/touchspin/:layout`, Component: <FormTouchspinContain /> },
  { path: `${process.env.PUBLIC_URL}/forms/widget/clipboard/:layout`, Component: <FormClipboardContain /> },

  { path: `${process.env.PUBLIC_URL}/forms/layout/formDefault/:layout`, Component: <FormDefault /> },
  { path: `${process.env.PUBLIC_URL}/forms/layout/formWizard/:layout`, Component: <FormWizard /> },

  // //Tables
  { path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable/:layout`, Component: <BasicTables /> },
  { path: `${process.env.PUBLIC_URL}/table/datatable/:layout`, Component: <DataTables /> },

  // //Applicatiion
  { path: `${process.env.PUBLIC_URL}/app/project/project-list/:layout`, Component: <Project /> },
  { path: `${process.env.PUBLIC_URL}/app/project/new-project/:layout`, Component: <Newproject /> },
  { path: `${process.env.PUBLIC_URL}/app/chat-app/chats/:layout`, Component: <Chat /> },
  { path: `${process.env.PUBLIC_URL}/app/chat-app/chat-video-app/:layout`, Component: <VideoChat /> },
  { path: `${process.env.PUBLIC_URL}/app/contact-app/contacts/:layout`, Component: <Contact /> },

  { path: `${process.env.PUBLIC_URL}/app/task/:layout`, Component: <Task /> },
  { path: `${process.env.PUBLIC_URL}/app/bookmark/:layout`, Component: <BookmarksContain /> },
  { path: `${process.env.PUBLIC_URL}/app/todo-app/todo/:layout`, Component: <TodoContain /> },

  { path: `${process.env.PUBLIC_URL}/app/users/profile/:layout`, Component: <UsersProfileContain /> },
  { path: `${process.env.PUBLIC_URL}/app/users/edit/:layout`, Component: <UsersEditContain /> },
  { path: `${process.env.PUBLIC_URL}/app/users/cards/:layout`, Component: <UsersCardssContain /> },
  { path: `${process.env.PUBLIC_URL}/app/social-app/:layout`, Component: <SocialAppContain /> },

  { path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar/:layout`, Component: <DraggableContain /> },

  { path: `${process.env.PUBLIC_URL}/app/email-app/:layout`, Component: <MailInboxContain /> },
  { path: `${process.env.PUBLIC_URL}/app/file-manager/:layout`, Component: <FileManagerContain /> },
  { path: `${process.env.PUBLIC_URL}/app/search/:layout`, Component: <SearchResultContain /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/product/:layout`, Component: <Products /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:layout/:id`, Component: <ProductPage /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details/:layout`, Component: <PaymentDetails /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory/:layout`, Component: <OrderHistory /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing/:layout`, Component: <PricingMemberShip /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice/:layout`, Component: <Invoice /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/cart/:layout`, Component: <ProductCart /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist/:layout`, Component: <WishList /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout/:layout`, Component: <CheckOut /> },
  { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list/:layout`, Component: <ProductListContain /> },
  // //Gallery
  { path: `${process.env.PUBLIC_URL}/app/gallery/grids/:layout`, Component: <ImageGallery /> },
  { path: `${process.env.PUBLIC_URL}/app/gallery/griddesc/:layout`, Component: <ImageWithDesc /> },
  { path: `${process.env.PUBLIC_URL}/app/gallery/masonrys/:layout`, Component: <MasonryGallery /> },
  { path: `${process.env.PUBLIC_URL}/app/gallery/masonrydesc/:layout`, Component: <MesonryDesc /> },
  { path: `${process.env.PUBLIC_URL}/app/gallery/hover_effect/:layout`, Component: <ImageHover /> },

  // //page
  { path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`, Component: <SamplePage /> },

  // //Blog
  { path: `${process.env.PUBLIC_URL}/app/blog/blogdetails/:layout`, Component: <BlogDetail /> },
  { path: `${process.env.PUBLIC_URL}/app/blog/blogsingle/:layout`, Component: <BlogSingle /> },
  { path: `${process.env.PUBLIC_URL}/app/blog/blogpost/:layout`, Component: <BlogPost /> },

  // //Job Search
  { path: `${process.env.PUBLIC_URL}/app/jobsearch/cardview/:layout`, Component: <JobCards /> },
  { path: `${process.env.PUBLIC_URL}/app/jobsearch/joblist/:layout`, Component: <ListView /> },
  { path: `${process.env.PUBLIC_URL}/app/jobsearch/jobdetail/:layout`, Component: <JobDetails /> },
  { path: `${process.env.PUBLIC_URL}/app/jobsearch/jobapply/:layout`, Component: <Apply /> },

  // //Learning
  { path: `${process.env.PUBLIC_URL}/app/learning/learninglist/:layout`, Component: <LearningList /> },
  { path: `${process.env.PUBLIC_URL}/app/learning/learningdetail/:layout`, Component: <DetailCourse /> },

  // //Map
  { path: `${process.env.PUBLIC_URL}/app/map/googlemap/:layout`, Component: <GoogleMaps /> },
  { path: `${process.env.PUBLIC_URL}/app/map/pigeonmap/:layout`, Component: <PigeonMap /> },

  // //Editor
  { path: `${process.env.PUBLIC_URL}/editor/ckeditor/:layout`, Component: <CkEditorContain /> },
  { path: `${process.env.PUBLIC_URL}/editor/mdeeditor/:layout`, Component: <MdeEDitorContain /> },
  { path: `${process.env.PUBLIC_URL}/editor/aceeditor/:layout`, Component: <ACEEditorContain /> },

  // //Knowledge
  { path: `${process.env.PUBLIC_URL}/app/knowledgebase/:layout`, Component: <KnowledgeBaseContain /> },
  { path: `${process.env.PUBLIC_URL}/app/knowledgebase-category/:layout`, Component: <KnowledgeCategoryContain /> },
  { path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail/:layout`, Component: <KnowledgeDetailContain /> },

  // //Support Ticket
  { path: `${process.env.PUBLIC_URL}/app/supportticket/:layout`, Component: <SupportTickitContain /> },

  // //Faq
  { path: `${process.env.PUBLIC_URL}/app/faq/:layout`, Component: <FAQContent /> },


  //Dashboard Admin
  { path: `${process.env.PUBLIC_URL}/admin/dashboard/sales/:layout`, Component: <DashboardAdmin /> },
  { path: `${process.env.PUBLIC_URL}/admin/dashboard/tenant/:layout`, Component: <TenantDashboard /> },


  //Dashboard Super Admin
  { path: `${process.env.PUBLIC_URL}/controlpanel/dashboard/default/:layout`, Component: <DashboardSAdmin /> },


  //Concessionaire
  { path: `${process.env.PUBLIC_URL}/controlpanel/concessionaire/addnewconcessionaire/:layout`, Component: <AddNewConcessionaire /> },
  { path: `${process.env.PUBLIC_URL}/controlpanel/concessionaire/viewconcessionaire/:layout`, Component: <ConcessionaireList /> },
  { path: `${process.env.PUBLIC_URL}/controlpanel/concessionaire/viewconcessionairedetail/:id`, Component: <ViewConcessionaire /> },
  { path: `${process.env.PUBLIC_URL}/controlpanel/concessionaire/editconcessionaire/:id`, Component: <EditConcessionaire /> },

  //Sadmin Settings
  { path: `${process.env.PUBLIC_URL}/controlpanel/users/:layout`, Component: <SadminUsers /> },
  { path: `${process.env.PUBLIC_URL}/admin/home/:layout`, Component: <BackendUserHome /> },
  { path: `${process.env.PUBLIC_URL}/admin/viewapikey/:layout`, Component: <ViewAPIKey /> },
  { path: `${process.env.PUBLIC_URL}/controlpanel/log/:layout`, Component: <SadminLog /> },
  { path: `${process.env.PUBLIC_URL}/controlpanel/errorlog/:layout`, Component: <ErrorLog /> },


  //Contry
  { path: `${process.env.PUBLIC_URL}/admin/country/addcountry/:layout`, Component: <AddCountry /> },
  { path: `${process.env.PUBLIC_URL}/admin/country/viewcountry/:layout`, Component: <ViewCountry /> },
  { path: `${process.env.PUBLIC_URL}/admin/country/countrydetail/:id`, Component: <CountryView /> },
  { path: `${process.env.PUBLIC_URL}/admin/country/editcountry/:id`, Component: <EditCountry /> },

  //Property
  { path: `${process.env.PUBLIC_URL}/admin/property/addproperty/:layout`, Component: <AddProperty /> },
  { path: `${process.env.PUBLIC_URL}/admin/property/viewproperty/:layout`, Component: <Properties /> },
  { path: `${process.env.PUBLIC_URL}/admin/property/propertydetail/:id`, Component: <PropertyDetails /> },
  { path: `${process.env.PUBLIC_URL}/admin/property/editproperty/:id`, Component: <EditProperty /> },

  //Category
  { path: `${process.env.PUBLIC_URL}/admin/category/addcategory/:layout`, Component: <AddCategory /> },
  { path: `${process.env.PUBLIC_URL}/admin/category/viewcategory/:layout`, Component: <ViewCategory /> },
  { path: `${process.env.PUBLIC_URL}/admin/category/editcategory/:id`, Component: <EditCategory /> },

  //Subcategory
  { path: `${process.env.PUBLIC_URL}/admin/subcategory/addsubcategory/:layout`, Component: <AddSubCategory /> },
  { path: `${process.env.PUBLIC_URL}/admin/subcategory/viewsubcategory/:layout`, Component: <ViewSubcategory /> },
  { path: `${process.env.PUBLIC_URL}/admin/subcategory/editsubcategory/:id`, Component: <EditSubCategory /> },

  //Tenant
  { path: `${process.env.PUBLIC_URL}/admin/tenant/newtenant/:layout`, Component: <AddNewTenant /> },
  { path: `${process.env.PUBLIC_URL}/admin/tenant/viewtenant/:layout`, Component: <ViewTenant /> },
  { path: `${process.env.PUBLIC_URL}/admin/tenant/tenantdetail/:id`, Component: <TenantDetail /> },
  { path: `${process.env.PUBLIC_URL}/admin/tenant/tenantedit/:id`, Component: <EditTenant /> },
  { path: `${process.env.PUBLIC_URL}/admin/tenant/tenantstatuschange/:id`, Component: <TenantStatusChange /> },

  //Configurations

  { path: `${process.env.PUBLIC_URL}/admin/configurations/:layout`, Component: <Configurations /> },

  //Analytics
  { path: `${process.env.PUBLIC_URL}/admin/analytics/:layout`, Component: <Analytics /> },

  //Reports
  { path: `${process.env.PUBLIC_URL}/admin/report/daywisereport/:layout`, Component: <DaywiseReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/daywiseconsolidated/:layout`, Component: <DaywiseConsolidated /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/revenuesharereport/:layout`, Component: <RevenueShareReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/tradingdensityreport/:layout`, Component: <TradingDensityReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/dailycomprehensiverevenue/:layout`, Component: <DailyComprehensiveReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/growthanalysisreport/:layout`, Component: <GrowthAnalysisReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/deliverypartnerreport/:layout`, Component: <DeliveryPartnerReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/fbsalessummary/:layout`, Component: <FbSalesSummary /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/missingdates/:layout`, Component: <MissingDatesReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/report/monthwisesales/:layout`, Component: <MonthwiseSalesReport /> },

  //Data Grid
  { path: `${process.env.PUBLIC_URL}/admin/datagrid/:layout`, Component: <DataGrid /> },

  //Admin settings
  { path: `${process.env.PUBLIC_URL}/admin/userprofiles/:layout`, Component: <UserProfile /> },
  { path: `${process.env.PUBLIC_URL}/admin/users/:layout`, Component: <Users /> },
  { path: `${process.env.PUBLIC_URL}/admin/logs/:layout`, Component: <Log /> },
  { path: `${process.env.PUBLIC_URL}/admin/tenantportal/:layout`, Component: <TenantPortal /> },

  //Dynamic Reports
  { path: `${process.env.PUBLIC_URL}/admin/dynamicreports/:layout`, Component: <DynamicReports /> },
  { path: `${process.env.PUBLIC_URL}/admin/runreport/:id`, Component: <RunReport /> },
  { path: `${process.env.PUBLIC_URL}/admin/schedulereports/:layout`, Component: <ScheduleReport /> },

  //change Password
  { path: `${process.env.PUBLIC_URL}/account/changepassword/:layout`, Component: <ChangePassword /> },

  //Tenant Login
  { path: `${process.env.PUBLIC_URL}/tenant/home/:layout`, Component: <TenantHome /> },
  { path: `${process.env.PUBLIC_URL}/tenant/apikey/generateapikey/:layout`, Component: <ApiKey /> },



];
