import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table } from 'reactstrap';
import axios from 'axios';
import { Btn, H6, } from '../../../AbstractElements';
import config from '../../../config';
import { toast } from 'react-toastify';

const ScheduleReportView = () => {

    if (localStorage.getItem("manageScheduleReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const [reports, setReports] = useState([]);
    const [scheduleReports, setScheduleReports] = useState([]);
    const [viewType, setViewType] = useState('');
    const [scheduleReportName, setScheduleReportName] = useState('');
    const [reportId, setReportId] = useState('');
    const [recipients, setRecipients] = useState('');
    const [frequency, setFrequency] = useState('');
    const [alertTime, setAlertTime] = useState('09:00');
    const [scheduleReportId, setScheduleReportId] = useState('');
    const [disable, setDisable] = useState('');

    const [properties, setProperties] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${config.hostname}/dynamicreports/getreports`, configheader)
            .then((response) => {
                setReports(response.data.filter(report => report.report_name !== 'Zero Sale Detection'));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const fetchScheduleReports = async () => {
        try {
            const response = await axios.post(`${config.hostname}/schedulereports/getallschedulereport`, { concessionaire_id }, configheader);
            setScheduleReports(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchScheduleReports();
    }, []);

    const handleAddClick = (row) => {
        setViewType('Add')
    }

    const handlePropertyChange = (propertyName) => {
        let updatedProperties;

        if (selectedProperties.split(',').includes(propertyName)) {
            // Remove the value if it's already selected
            updatedProperties = selectedProperties
                .split(',')
                .filter(item => item !== propertyName)
                .join(',');
        } else {
            // Add the value if it's not already selected
            updatedProperties = selectedProperties
                ? `${selectedProperties},${propertyName}`
                : propertyName;
        }

        setSelectedProperties(updatedProperties);
    };


    const handleReportClick = async (id) => {
        try {
            setScheduleReportId(id)
            const response = await axios.get(`${config.hostname}/schedulereports/getSchedulereportbyid/${id}`, configheader);
            setScheduleReportName(response.data.schedule_report_name);
            setReportId(response.data.report_id);
            setRecipients(response.data.recipients)
            setFrequency(response.data.frequency);
            setAlertTime(response.data.alert_time)
            setDisable(response.data.disable)
            setSelectedProperties(response.data.property_access)
            setViewType('Edit');
        } catch (error) {
            console.error(error);
            toast.error('Error fetching report details');
        }
    }


    const handleAddSubmit = (e) => {
        e.preventDefault();
        handleAddNewSchedule();
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        handleUpdateSchedule();
    };

    const handleAddNewSchedule = async () => {

        if (selectedProperties === '') {
            toast.warning('Select Properties');
            return
        }

        const data = {
            concessionaire_id,
            login_id,
            report_id: reportId,
            schedule_report_name: scheduleReportName,
            recipients: recipients,
            frequency: frequency,
            alert_time: alertTime,
            created_by: login_id,
            property_access: selectedProperties
        };

        axios
            .post(`${config.hostname}/schedulereports/createschedulereport`, data, configheader)
            .then((response) => {
                toast.success('Report Added Successfully');
                fetchScheduleReports();
                setViewType('');
                setReportId('')
                setScheduleReportName('');
                setSelectedProperties('');
            })
            .catch((error) => {
                toast.error('Error Adding Report');
                console.log(error);
            });
    };

    const handleUpdateSchedule = async () => {

        if (selectedProperties === '') {
            toast.warning('Select Properties');
            return
        }

        const data = {
            concessionaire_id,
            login_id,
            report_id: reportId,
            schedule_report_name: scheduleReportName,
            recipients: recipients,
            frequency: frequency,
            alert_time: alertTime,
            created_by: login_id,
            disable,
            property_access: selectedProperties
        };

        axios
            .post(`${config.hostname}/schedulereports/updateschedulereport/${scheduleReportId}`, data, configheader)
            .then(() => {
                toast.success('Report Added Successfully');
                fetchScheduleReports();
                setViewType('');
                setReportId('')
                setScheduleReportId('')
                setScheduleReportName('');
                setSelectedProperties('');
            })
            .catch((error) => {
                toast.error('Error Adding Report');
                console.log(error);
            });
    };
    return (
        <Fragment>
            {viewType === '' && (
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Schedules</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New</Btn>
                        </div>
                    </CardHeader>
                    {scheduleReports.length > 0 &&
                        <CardBody>
                            <div className='table-responsive product-table'>
                                {
                                    <Table>
                                        <thead >
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col" >Notification Name</th>
                                                <th scope="col" >Frequency</th>
                                                <th scope="col" >Time</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {scheduleReports.map((item, index) => (
                                                <tr key={index} onClick={() => handleReportClick(item._id)}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.schedule_report_name}</td>
                                                    <td>{item.frequency}</td>
                                                    <td>{item.alert_time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    }
                </Card>
            )}
            {viewType === 'Add' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Add New Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleAddSubmit} >
                        <CardBody>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label>Reports</Label>
                                        <select name="report" id="report" className="form-control digits" required
                                            onChange={(e) => setReportId(e.target.value)} >
                                            <option value="">Select Report</option>
                                            {reports.map(report => (
                                                <option key={report._id} value={report._id}>{report.report_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Notification Name</Label>
                                        <input className="form-control" type="text" name="notificationName" placeholder="Notification Name"
                                            onChange={(e) => setScheduleReportName(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Recipients</Label>
                                        <input className="form-control" type="text" name="mailingList" placeholder="Recipients"
                                            onChange={(e) => setRecipients(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Frequency </Label>
                                        <select className="form-control form-control-primary btn-square mb-3" name="frequency" type="select"
                                            onChange={(e) => setFrequency(e.target.value)} required>
                                            <option value="">Select Frequency</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup >
                                        <Label>Alert Time</Label>
                                        <Input className="form-control digits" type="time" defaultValue="09:00:00"
                                            onChange={(e) => setAlertTime(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label>Properties</label>
                                        <div
                                            onClick={toggleDropdown}
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                {selectedProperties.length > 0
                                                    ? selectedProperties : 'Select Properties'}
                                            </div>
                                            <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                        </div>
                                        {dropdownOpen && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    width: '100%',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px',
                                                    marginTop: '4px',
                                                    zIndex: 1,
                                                }}
                                            >
                                                {properties.map(property => (
                                                    <label key={property._id} style={{ display: 'block', padding: '2px', cursor: 'pointer' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={property._id}
                                                            checked={selectedProperties.includes(property.property_name)}
                                                            onChange={() => handlePropertyChange(property.property_name)}
                                                            style={{ marginRight: '8px' }}
                                                            required
                                                        />
                                                        {property.property_name}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Save Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
            {viewType === 'Edit' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Edit Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleEditSubmit}>
                        <CardBody>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label>Reports</Label>
                                        <select name="report" id="report" className="form-control digits" required
                                            onChange={(e) => setReportId(e.target.value)}
                                            value={reportId}>
                                            <option value="">Select Report</option>
                                            {reports.map(report => (
                                                <option key={report._id} value={report._id}>{report.report_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Report Name</Label>
                                        <Input
                                            type="text"
                                            name="report_name"
                                            className="form-control"
                                            value={scheduleReportName}
                                            onChange={(e) => setScheduleReportName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Recipients</Label>
                                        <input className="form-control" type="text" name="mailingList" placeholder="Recipients" value={recipients}
                                            onChange={(e) => setRecipients(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Frequency </Label>
                                        <select className="form-control form-control-primary btn-square mb-3" name="frequency" type="select"
                                            onChange={(e) => setFrequency(e.target.value)}
                                            required
                                            value={frequency}>
                                            <option value="">Select Frequency</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup >
                                        <Label>Alert Time</Label>
                                        <Input className="form-control digits" type="time" value={alertTime} required
                                            onChange={(e) => setAlertTime(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label>Properties</label>
                                        <div
                                            onClick={toggleDropdown}
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                {selectedProperties.length > 0
                                                    ? selectedProperties : 'Select Properties'}
                                            </div>
                                            <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                        </div>
                                        {dropdownOpen && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    width: '100%',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px',
                                                    marginTop: '4px',
                                                    zIndex: 1,
                                                }}
                                            >
                                                {properties.map(property => (
                                                    <label key={property._id} style={{ display: 'block', padding: '2px', cursor: 'pointer' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={property._id}
                                                            checked={selectedProperties.includes(property.property_name)}
                                                            onChange={() => handlePropertyChange(property.property_name)}
                                                            style={{ marginRight: '8px' }}
                                                            required
                                                        />
                                                        {property.property_name}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                {/* <Col>
                                    <FormGroup>
                                        <Label className="d-block" for="chk-ani2"> Disable </Label>
                                        <Input
                                            className="checkbox_animated"
                                            id="chkDisabale"
                                            type="checkbox"
                                            checked={disable}
                                            onChange={(e) => setDisable(e.target.checked)}
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col>
                                    <FormGroup className="d-flex align-items-center mt-4">
                                        <Label className="me-2" for="chk-ani2">Disable</Label>
                                        <Input
                                            className="checkbox_animated"
                                            id="chkDisabale"
                                            type="checkbox"
                                            checked={disable}
                                            onChange={(e) => setDisable(e.target.checked)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Update Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
        </Fragment>
    )
}

export default ScheduleReportView