import React, { Fragment, useState, useEffect } from 'react';
import { Btn, H4 } from '../../../../AbstractElements';
import { Card, CardBody, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, CardHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const GrowthAnalysisView = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const currentYear = new Date().getFullYear();
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [year, setYear] = useState(currentYear);
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [group_name, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [property_mall_code, setPropertyMallCode] = useState('');
    const [property_id, setPropertyId] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [monthlyAnalysisData, setMonthlyAnalysisData] = useState({ currentYearSales: [], previousYearSales: [] });
    const [yearlyAnalysisData, setYearlyAnalysisData] = useState([]);
    const [currentYearTotal, setCurrentYearTotal] = useState('')
    const [previousYearTotal, setPreviousYearTotal] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const token = localStorage.getItem("sessiontoken");
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);

    const handleYearChange = (e) => {
        setYear(e.target.value);
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });
        setYearlyAnalysisData();
    };

    const handlePropertyChange = (e) => {
        const selectedProperty = properties.find(property => property._id === e.target.value);
        setPropertyMallCode(selectedProperty ? selectedProperty.property_mall_code : '');
        setPropertyId(e.target.value);
        setTenantId('')
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });
        setYearlyAnalysisData();
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setSubcategoryId('')
        setTenantId('');
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });
        setYearlyAnalysisData();
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });
        setYearlyAnalysisData();
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
    };

    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });
        setYearlyAnalysisData();
    };

    const generateReport = async (e) => {
        e.preventDefault();
        setLoading(true);

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';
        const tenant_name = tenant_id !== '' ? tenants.find(tenant => tenant._id === tenant_id).tenant_store_name : '';

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            year,
            group_name,
            category_name,
            property_name,
            subcategory_name,
            tenant_name,
        };

        axios
            .post(`${config.hostname}/reports/getmonthlygrowthanalysisreport`, data, configheader)
            .then((response) => {
                setMonthlyAnalysisData(response.data);
                setCurrentYearTotal(response.data.totalCurrentYearSales);
                setPreviousYearTotal(response.data.totalPreviousYearSales)
                setLoading(false);
                console.log(response.data);
                insertToLog(" generated Growth Analysis Report")
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Error');
                console.log(error);
            });

        axios
            .post(`${config.hostname}/reports/getyearlygrowthanalysisreport`, data, configheader)
            .then((response) => {
                setYearlyAnalysisData(response.data);
                console.log(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Error');
                console.log(error);
            });
    };


    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleExport = (format) => {
        ///if (yearlyAnalysisData.length > 0 || monthlyAnalysisData.length > 0) {
        const metaData = [
            ['Year: ', year],
            ['Property: ', property_mall_code ? properties.find(p => p.property_mall_code === property_mall_code)?.property_name : 'All'],
            ['Category', category_id ? categories.find(s => s._id === category_id)?.category_name : 'All'],
            ['Subcategory: ', subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'],
            ['Group', group_name ? group_name : 'All'],
            ['Tenant: ', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'],
            ['Exported By:', login_name],
            ['Exported On:', new Date().toLocaleString()]
        ];

        if (format === 'excel') {
            exportToExcel(yearlyAnalysisData, monthlyAnalysisData, year, metaData);
            insertToLog(" exported Growth Analysis Report to excel")
        } else if (format === 'pdf') {
            exportToPDF(yearlyAnalysisData, monthlyAnalysisData, year, metaData);
            insertToLog(" exported Growth Analysis Report to pdf")
        }
        // }
    };

    const exportToExcel = (yearlyAnalysisData, monthlyAnalysisData, year, metaData) => {
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        const yearlyAnalysisHeaders = ["Year", "Sales", "YOY", "YTD", "Change", "MTD", "Change"];
        // const yearlyAnalysisDataArray = [
        //     [year - 1, yearlyAnalysisData.previousYearSale, "-", yearlyAnalysisData.ytdPreviousYear, "-", yearlyAnalysisData.mtdPreviousYear, "-"],
        //     [year, yearlyAnalysisData.currentYearSale, yearlyAnalysisData.yoyCurrentYear, yearlyAnalysisData.ytdCurrentYear, yearlyAnalysisData.growthYTD, yearlyAnalysisData.mtdCurrentYear, yearlyAnalysisData.growthMTD]
        // ];

        const yearlyAnalysisDataArray = [
            [
                year - 1,
                Number(yearlyAnalysisData.previousYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-",
                Number(yearlyAnalysisData.ytdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-",
                Number(yearlyAnalysisData.mtdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-"
            ],
            [
                year,
                Number(yearlyAnalysisData.currentYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.yoyCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.ytdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.growthYTD).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.mtdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.growthMTD).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            ]
        ];


        const monthlyAnalysisHeaders = ["Month", `${year - 1}`, "MOM Change", `${year}`, "MOM Change", "YOY Change"];
        const monthlyAnalysisDataArray = monthlyAnalysisData.previousYearSales.map((item, index) => {
            const currentYearData = monthlyAnalysisData.currentYearSales[index];
            return [
                item.month,
                // item.totalSales.toFixed(2),
                Number(item.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                parseFloat(item.percentageChange) === 0 || parseFloat(item.percentageChange) === -100 ? '-' : item.percentageChange,
                // currentYearData.totalSales.toFixed(2),
                Number(currentYearData.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                parseFloat(currentYearData.percentageChange) === 0 || parseFloat(currentYearData.percentageChange) === -100 ? '-' : currentYearData.percentageChange,
                parseFloat(currentYearData.YOYChange) === 0 || parseFloat(currentYearData.YOYChange) === -100 || currentYearData.YOYChange === '-' ? '-' : currentYearData.YOYChange
            ];
        });

        const combinedData = [
            ['Growth Analysis Report'],
            ...metaData,
            [],
            ['Yearly Analysis'],
            yearlyAnalysisHeaders,
            ...yearlyAnalysisDataArray,
            [],
            ['Monthly Analysis'],
            monthlyAnalysisHeaders,
            ...monthlyAnalysisDataArray
        ];

        const ws = XLSX.utils.aoa_to_sheet(combinedData);

        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }, // Merge cells for "Growth Analysis Report"
            { s: { r: metaData.length + 3, c: 0 }, e: { r: metaData.length + 3, c: 6 } }, // Merge cells for "Yearly Analysis"
            { s: { r: metaData.length + 7 + yearlyAnalysisDataArray.length, c: 0 }, e: { r: metaData.length + 7 + yearlyAnalysisDataArray.length, c: 4 } } // Merge cells for "Monthly Analysis"
        ];

        XLSX.utils.book_append_sheet(wb, ws, 'Growth Analysis Report');
        XLSX.writeFile(wb, 'GrowthAnalysisReport.xlsx');
    };


    const getStyledValue = (value) => {
        const numericValue = parseFloat(value);
        let style = {};
        let arrow = '';

        if (numericValue > 0) {
            style = { color: 'green' };
            arrow = '↑';
        } else if (numericValue < 0) {
            style = { color: 'red' };
            arrow = '↓';
        }

        return (
            <span style={style}>
                {value}% {arrow}
            </span>
        );
    };


    const exportToPDF = (yearlyAnalysisData, monthlyAnalysisData, year, metaData) => {
        const doc = new jsPDF();

        // Add main heading and center align it
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(10);
        const titleText = 'Growth Analysis Report';
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textOffset = (pageWidth - textWidth) / 2;
        doc.text(titleText, textOffset, 16);

        // Add metaData
        doc.setFontSize(8);
        metaData.forEach((data, index) => {
            doc.text(`${data[0]} ${data[1]}`, 14, 26 + (index * 5));
        });

        const metaDataEndY = 26 + (metaData.length * 10) + 10;

        // Add Yearly Analysis heading and align it to the left
        doc.setFontSize(16);
        doc.text('Yearly Analysis', 14, metaDataEndY);

        // Prepare Yearly Analysis table data
        const yearlyAnalysisHeaders = ["Year", "Sales", "YOY", "YTD", "Change", "MTD", "Change"];
        // const yearlyAnalysisDataArray = [
        //     [year - 1, yearlyAnalysisData.previousYearSale, "-", yearlyAnalysisData.ytdPreviousYear, "-", yearlyAnalysisData.mtdPreviousYear, "-"],
        //     [year, yearlyAnalysisData.currentYearSale, yearlyAnalysisData.yoyCurrentYear, yearlyAnalysisData.ytdCurrentYear, yearlyAnalysisData.growthYTD, yearlyAnalysisData.mtdCurrentYear, yearlyAnalysisData.growthMTD]
        // ];

        const yearlyAnalysisDataArray = [
            [
                year - 1,
                Number(yearlyAnalysisData.previousYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-",
                Number(yearlyAnalysisData.ytdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-",
                Number(yearlyAnalysisData.mtdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "-"
            ],
            [
                year,
                Number(yearlyAnalysisData.currentYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.yoyCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.ytdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.growthYTD).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.mtdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                Number(yearlyAnalysisData.growthMTD).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            ]
        ];


        // Add Yearly Analysis table
        doc.autoTable({
            head: [yearlyAnalysisHeaders],
            body: yearlyAnalysisDataArray,
            startY: metaDataEndY + 10
        });

        // Add some space before the next table
        const finalY = doc.autoTable.previous.finalY + 10;

        // Add Monthly Analysis heading and align it to the left
        doc.setFontSize(16);
        doc.text('Monthly Analysis', 14, finalY);

        // Prepare Monthly Analysis table data
        const monthlyAnalysisHeaders = ["Month", `${year - 1}`, "MOM Change", `${year}`, "MOM Change", "YOY Change"];
        const monthlyAnalysisDataArray = monthlyAnalysisData.previousYearSales.map((item, index) => {
            const currentYearData = monthlyAnalysisData.currentYearSales[index];
            return [
                item.month,
                // item.totalSales.toFixed(2),
                Number(item.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                parseFloat(item.percentageChange) === 0 || parseFloat(item.percentageChange) === -100 ? '-' : item.percentageChange,
                // currentYearData.totalSales.toFixed(2),
                Number(currentYearData.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                parseFloat(currentYearData.percentageChange) === 0 || parseFloat(currentYearData.percentageChange) === -100 ? '-' : currentYearData.percentageChange,
                parseFloat(currentYearData.YOYChange) === 0 || parseFloat(currentYearData.YOYChange) === -100 || currentYearData.YOYChange === '-' ? '-' : currentYearData.YOYChange
            ];
        });

        // Add Monthly Analysis table
        doc.autoTable({
            head: [monthlyAnalysisHeaders],
            body: monthlyAnalysisDataArray,
            startY: finalY + 10
        });

        // Save the PDF
        doc.save('GrowthAnalysisReport.pdf');
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const reset = () => {
        setPropertyId('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setYearlyAnalysisData([]);
        setMonthlyAnalysisData({ currentYearSales: [], previousYearSales: [] });

    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="year">Year</Label>
                                    <select name="year" id="year" className="form-control digits"
                                        value={year} onChange={handleYearChange}>
                                        <option value={currentYear}>{currentYear}</option>
                                        <option value={currentYear - 1}>{currentYear - 1}</option>
                                        <option value={currentYear - 2}>{currentYear - 2}</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="category_id">Category</Label>
                                    <select name="category_id" id="category_id" className="form-control"
                                        value={category_id} onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('pdf'),
                                        onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                </Btn>
                                {tooltip === 'Export to PDF' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <H4 attrH4={{ className: 'card-title mb-0', style: { fontSize: '17px' } }}>Yearly Analysis</H4>
                    </CardHeader>
                    {loading === true ? (
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-3' }} />
                        </div>

                    ) : (
                        <CardBody>
                            <Col sm="12" lg="12" xl="12">
                                <div className="table-responsive">

                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Year</th>
                                                <th scope="col">Sales</th>
                                                <th scope="col">YOY</th>
                                                <th scope="col">YTD</th>
                                                <th scope="col">Change</th>
                                                <th scope="col">MTD</th>
                                                <th scope="col">Change</th>
                                            </tr>
                                        </thead>
                                        {yearlyAnalysisData && Object.keys(yearlyAnalysisData).length > 0 && (
                                            <tbody>
                                                {/* <tr>
                                                    <td>{year - 1}</td>
                                                    <td>{Number(yearlyAnalysisData.previousYearSale).toLocaleString()}</td>
                                                    <td>-</td>
                                                    <td>{Number(yearlyAnalysisData.ytdPreviousYear).toLocaleString()}</td>
                                                    <td>-</td>
                                                    <td>{Number(yearlyAnalysisData.mtdPreviousYear).toLocaleString()}</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr> */}
                                                <tr>
                                                    <td>{year - 1}</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.previousYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>-</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.ytdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>-</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.mtdPreviousYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>

                                                {/* <tr>
                                                    <td>{year}</td>
                                                    <td>{yearlyAnalysisData.currentYearSale}</td>
                                                    <td>{getStyledValue(yearlyAnalysisData.yoyCurrentYear)}</td>
                                                    <td>{yearlyAnalysisData.ytdCurrentYear}</td>
                                                    <td>{getStyledValue(yearlyAnalysisData.growthYTD)}</td>
                                                    <td>{yearlyAnalysisData.mtdCurrentYear}</td>
                                                    <td>{getStyledValue(yearlyAnalysisData.growthMTD)}</td>
                                                    <td></td>
                                                </tr> */}
                                                <tr>
                                                    <td>{year}</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.currentYearSale).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>{getStyledValue(yearlyAnalysisData.yoyCurrentYear)}</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.ytdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>{getStyledValue(yearlyAnalysisData.growthYTD)}</td>
                                                    <td>
                                                        {Number(yearlyAnalysisData.mtdCurrentYear).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td>{getStyledValue(yearlyAnalysisData.growthMTD)}</td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                        )}
                                    </Table>
                                </div>
                            </Col>
                        </CardBody>
                    )}
                </Card>
                <Card>
                    <CardHeader>
                        <H4 attrH4={{ className: 'card-title mb-0', style: { fontSize: '17px' } }}>Monthly Analysis</H4>
                    </CardHeader>
                    {loading === true ? (
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-3' }} />
                        </div>

                    ) : (
                        <CardBody>
                            <Col sm="12" lg="12" xl="12">
                                <div className="table-responsive">

                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Month</th>
                                                <th scope="col">{year - 1}</th>
                                                <th scope="col">MOM Change</th>
                                                <th scope="col">{year}</th>
                                                <th scope="col">MOM Change</th>
                                                <th scope="col">YOY Change</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {monthlyAnalysisData.previousYearSales.map((item, index) => {
                                                const currentYearData = monthlyAnalysisData.currentYearSales[index];
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.month}</td>
                                                        {/* <td>{item.totalSales.toFixed(2)}</td> */}
                                                        <td>{Number(item.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        <td>{parseFloat(item.percentageChange) === 0 || parseFloat(item.percentageChange) === -100 ? '-' : getStyledValue(item.percentageChange)}</td>
                                                        {/* <td>{currentYearData.totalSales.toFixed(2)}</td> */}
                                                        <td>
                                                            {Number(currentYearData.totalSales).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}
                                                        </td>

                                                        <td>{parseFloat(currentYearData.percentageChange) === 0 || parseFloat(currentYearData.percentageChange) === -100 ? '-' : getStyledValue(currentYearData.percentageChange)}</td>
                                                        {/* <td>
                                                            {item.totalSales && currentYearData.totalSales
                                                                ? getStyledValue(
                                                                    (((currentYearData.totalSales - item.totalSales) / item.totalSales) * 100).toFixed(2)
                                                                )
                                                                : '-'}
                                                        </td> */}
                                                        <td>{parseFloat(currentYearData.YOYChange) === 0 || parseFloat(currentYearData.YOYChange) === -100 || currentYearData.YOYChange === '-' ? '-' : getStyledValue(currentYearData.YOYChange)}</td>
                                                        <td></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td >Total</td>
                                                <td>{Number(previousYearTotal).toLocaleString()}</td>
                                                <td>{Number(currentYearTotal).toLocaleString()}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>

                                </div>
                            </Col>
                        </CardBody>
                    )}
                </Card>

            </Form>
        </Fragment>
    )
}

export default GrowthAnalysisView;
