import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import MonthwiseSalesView from './MonthwiseSalesView';

const MonthwiseSalesReport = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Report" title="Monthwise Sales Report" mainTitle="Monthwise Sales Report" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <MonthwiseSalesView />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default MonthwiseSalesReport