import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap';
import { Widgets2ChartData, Widgets2ChartData2, Widgets2ChartData2Widgets, Widgets2ChartDataWidgets } from '../../../../Data/DefaultDashboard/Chart';
import Widgets1 from '../../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../../Common/CommonWidgets/Widgets2';
import axios from 'axios';
import config from '../../../../config';
import { H4, H5, Btn } from '../../../../AbstractElements';
import { useNavigate } from 'react-router';

const WidgetsWrapper = ({ tenantId, startDate, endDate }) => {

  const token = localStorage.getItem("sessiontoken");
  const layout = localStorage.getItem("layout");
  const configheader = {
    headers: {
      Authorization: `${token}`
    }
  };

  const navigate = useNavigate();

  const [totalSales, setTotalSale] = useState('');
  const [totalRevenue, setTotalRevenue] = useState('');
  const [yesterdaySales, setYesterdaySale] = useState('');
  const [thisWeekSale, setThisWeekSale] = useState('');
  const [thisMonthSale, setThisMonthSale] = useState('');
  const [thisYearSale, setThisYearSale] = useState('');
  const [lastYearSale, setLastYearSale] = useState('');
  const [yesterdayRevenue, setYesterdayRevenue] = useState('');
  const [thisWeekRevenue, setThisWeekRevenue] = useState('');
  const [thisMonthRevenue, setThisMonthRevenue] = useState('');
  const [thisYearRevenue, setThisYearRevenue] = useState('');
  const [lastYearRevenue, setLastYearRevenue] = useState('');
  const [tenantdetails, setTenantDetails] = useState({});
  const [leaseStartDate, setLeaseStartDate] = useState('');
  const [leaseEndDate, setLeaseEndDate] = useState('');
  const [liveDate, setLiveDate] = useState('');

  const concessionaire_id = localStorage.getItem("concessionaireId");
  const property_id = localStorage.getItem("propertyAccess");
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  useEffect(() => {
    axios.get(`${config.hostname}/tenant/${tenantId}`, configheader)
      .then((response) => {
        setTenantDetails(response.data)
        const leaseStartDate = new Date(response.data.tenant_lease_startdate).toISOString().split('T')[0];
        setLeaseStartDate(leaseStartDate);
        const leaseEndDate = new Date(response.data.tenant_lease_enddate).toISOString().split('T')[0];
        setLeaseEndDate(leaseEndDate);
        const liveDate = new Date(response.data.tenant_live_date).toISOString().split('T')[0];
        setLiveDate(liveDate);

      })
      .catch((error) => {
        console.log(error);
      });
  }, [tenantId]);

  useEffect(() => {
    const data = {
      concessionaire_id,
      tenant_id: tenantId,
      property_id
    }
    axios
      .post(`${config.hostname}/tenantdashboard/getdetails`, data, configheader)
      .then((response) => {
        setYesterdaySale(Number(response.data.salesYesterday).toLocaleString());
        setThisWeekSale(Number(response.data.salesThisWeek).toLocaleString());
        setThisMonthSale(Number(response.data.salesThisMonth).toLocaleString());
        setThisYearSale(Number(response.data.salesThisYear).toLocaleString());
        setLastYearSale(Number(response.data.salesPreviousYear).toLocaleString());
        setYesterdayRevenue(Number(response.data.revenueYesterday).toLocaleString());
        setThisWeekRevenue(Number(response.data.revenueThisWeek).toLocaleString());
        setThisMonthRevenue(Number(response.data.revenueThisMonth).toLocaleString());
        setThisYearRevenue(Number(response.data.revenueThisYear).toLocaleString());
        setLastYearRevenue(Number(response.data.revenuePreviousYear).toLocaleString());
      })
      .catch((error) => {
        console.log(error);
        setYesterdaySale('');
        setThisWeekSale('');
        setThisMonthSale('');
        setThisYearSale('');
        setLastYearSale('');
        setYesterdayRevenue('');
        setThisWeekRevenue('');
        setThisMonthRevenue('');
        setThisYearRevenue('');
        setLastYearRevenue('');
      });

  }, [tenantId]);

  useEffect(() => {
    const data = {
      concessionaire_id,
      tenant_id: tenantId,
      start_date: startDate,
      end_date: endDate
    }
    axios
      .post(`${config.hostname}/tenantdashboard/gettotals`, data, configheader)
      .then((response) => {
        setTotalSale(response.data.totalSales.toLocaleString());
        setTotalRevenue(response.data.totalRevenue.toLocaleString());
      })
      .catch((error) => {
        console.log(error);
        setTotalSale('');
        setTotalRevenue('');
      });

  }, [tenantId, startDate, endDate]);

  const WidgetsTotalSale = {
    title: 'Total Sales',
    // gros: 50,
    total: totalSales,
    color: 'primary',
    icon: 'profit',
  };

  const WidgetsTotalRevenue = {
    title: 'Total Revenue',
    // gros: 50,
    total: totalRevenue,
    color: 'primary',
    icon: 'profit',
  };

  const WidgetsYesterdaySale = {
    title: 'Yesterday',
    // gros: 50,
    total: yesterdaySales,
    color: 'primary',
    icon: 'profit',
  };
  const WidgetThisWeekSale = {
    title: 'This Week',
    // gros: 50,
    total: thisWeekSale,
    color: 'secondary',
    icon: 'profit',
  };
  const WidgetThisMonthSale = {
    title: 'This Month',
    // gros: 50,
    total: thisMonthSale,
    color: 'success',
    icon: 'profit',
  };
  const WidgetThisYearSale = {
    title: 'This Year',
    // gros: 50,
    total: thisYearSale,
    color: 'warning',
    icon: 'profit',
  };
  const WidgetLastYearSale = {
    title: 'Last Year - YTD',
    // gros: 50,
    total: lastYearSale,
    color: 'primary',
    icon: 'profit',
  };

  const WidgetsYesterdayRevenue = {
    title: 'Yesterday',
    // gros: 50,
    total: yesterdayRevenue,
    color: 'primary',
    icon: 'sale',
  };
  const WidgetThisWeekRevenue = {
    title: 'This Week',
    // gros: 50,
    total: thisWeekRevenue,
    color: 'secondary',
    icon: 'sale',
  };
  const WidgetThisMonthRevenue = {
    title: 'This Month',
    // gros: 50,
    total: thisMonthRevenue,
    color: 'success',
    icon: 'sale',
  };
  const WidgetThisYearRevenue = {
    title: 'This Year',
    // gros: 50,
    total: thisYearRevenue,
    color: 'warning',
    icon: 'sale',
  };
  const WidgetLastYearRevenue = {
    title: 'Last Year- YTD',
    // gros: 50,
    total: lastYearRevenue,
    color: 'primary',
    icon: 'sale',
  };

  const handleMoreClick = () => {
    navigate(`${process.env.PUBLIC_URL}/admin/report/daywisereport/${layout}`);
  }

  return (
    <>
      {tenantdetails &&
        tenantdetails._id && (
          <Row>
            <Col xl='9'>
              <Card className=' profile-box'>
                <CardBody>
                  <H5>
                    {tenantdetails.tenant_store_name}
                  </H5>
                  <div>{`Group: ${tenantdetails.tenant_group_name}`}</div>
                  <div> {`Property: ${tenantdetails.tenant_property_id?.property_name || 'N/A'}`}</div>
                  <div> {`Tenant Code: ${tenantdetails.tenant_code} Outlet Code: ${tenantdetails.tenant_unit_code}`}</div>
                  <div> {`Category: ${tenantdetails.tenant_category_id?.category_name}`}</div>
                  <div> {`Subcategory: ${tenantdetails.tenant_subcategory_id?.subcategory_name}`}</div>
                  <div>{`Live Date: ${liveDate}`}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{`Lease From: ${leaseStartDate} To: ${leaseEndDate}`}</div>
                    <div style={{ marginLeft: 'auto' }}>
                      <Btn attrBtn={{ color: 'transparent', outline: true, className: 'btn btn-outline-white', onClick: () => handleMoreClick() }}>More..</Btn>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Row>
                <Widgets1 data={WidgetsTotalSale} />
              </Row>
              <Row>
                <Widgets1 data={WidgetsTotalRevenue} />
              </Row>
            </Col>
          </Row >
        )}

      <Row>
        <Card>
          <CardBody>
            <H5> Sales</H5>
            <Row>
              <Col>
                <Widgets1 data={WidgetsYesterdaySale} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisWeekSale} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisMonthSale} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisYearSale} />
              </Col>
              <Col >
                <Widgets1 data={WidgetLastYearSale} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <H5>Revenue</H5>
            <Row>
              <Col>
                <Widgets1 data={WidgetsYesterdayRevenue} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisWeekRevenue} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisMonthRevenue} />
              </Col>
              <Col >
                <Widgets1 data={WidgetThisYearRevenue} />
              </Col>
              <Col >
                <Widgets1 data={WidgetLastYearRevenue} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>

    </>
  );
};

export default WidgetsWrapper;
